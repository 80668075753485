/* CSS Document */

/*BEGIN*/
body{
	margin:0;
	padding:0 0 10px 0;
	font-size:12px;
	font-family:Tahoma, Arial, serif;
	color:#333;
	background:#EEE;
}
body, html {
	margin: 0px;
	width:100%;
	height:100%;
}
img{
	border:none;
}

span.clever-link {
	text-decoration: underline;
	cursor: pointer;
}

.clearfix::after {
	display: block;
	content: "";
	clear: both;
}

a, span.clever-link{
	color:#38618C;
	text-decoration:none;
	cursor: pointer;
}
a:hover, span.clever-link:hover{
	color:#38618C;
	text-decoration:underline;
	cursor: pointer;
}
ul{
	display:inline-block;
	list-style:none;
	margin:0;
	padding:0;
}
li{
	float:left;
}
.video-js li {
	float: none !important;
}
select{
	font-family:Verdana, Geneva, sans-serif;
	color:#333;
	padding:0 0 0 5px;
	border:1px solid #999;
}
input[type="text"], input[type="tel"], input[type="email"]  {
	font-family:Verdana, Geneva, sans-serif;
	color:#333;
	padding:0 5px;
	border:1px solid #999;
}
textarea{
	font-family:Verdana, Geneva, sans-serif;
	color:#333;
	padding:0 5px;
	border:1px solid #999;
}
textarea:focus, input:focus, button:focus{
    outline: 0;
}
.errorLabel{
	color:#F00;
}
.errorItem{
	color:#F00;
	border-color:#F00;
	background:#EEE;
}
button.btBlue, a.btBlue {
	padding: 7px 26px 6px 8px;
	color: #FFF;
	border: none;
	border-radius: 5px;
	white-space: nowrap;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	line-height: 28px;
	display: inline-block;
	width: auto;
	cursor:pointer;
	background: #38618C;
	vertical-align:bottom;
}
button.btBlue:hover, a.btBlue:hover {
	background: #6e8eb0;
}
button.btRed, a.btRed {
	padding: 7px 26px 6px 8px;
	color: #FFF;
	border: none;
	border-radius: 5px;
	white-space: nowrap;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	line-height: 28px;
	display: inline-block;
	width: auto;
	cursor:pointer;
	background: #BF111B;
	vertical-align:bottom;
}
button.btRed:hover, a.btRed:hover {
	background: #ca3941;
}
button.btGray, a.btGray {
	padding: 7px 26px 6px;
	margin: 8px 10px 0 0;
	color: #222;
	border: solid 1px #d9dad9;
	border-radius: 5px;
	white-space: nowrap;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	line-height: 28px;
	display: inline-block;
	width: auto;
	cursor:pointer;
	background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #d2d2d2));
	background-image: -webkit-linear-gradient(#fdfdfd 0%, #d2d2d2 100%);
	background-image: -moz-linear-gradient(#fdfdfd 0%, #d2d2d2 100%);
	background-image: -o-linear-gradient(#fdfdfd 0%, #d2d2d2 100%);
	background-image: linear-gradient(#fdfdfd 0%, #d2d2d2 100%);
	background-color:#f0f0f0;
	vertical-align:bottom;
}
button.btGray:hover, a.btGray:hover {
	color: #000;
	background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fafafa), color-stop(100%, #bababa));
	background-image: -webkit-linear-gradient(#fafafa 0%, #bababa 100%);
	background-image: -moz-linear-gradient(#fafafa 0%, #bababa 100%);
	background-image: -o-linear-gradient(#fafafa 0%, #bababa 100%);
	background-image: linear-gradient(#fafafa 0%, #bababa 100%);
	background-color:#d9dad9;
	vertical-align:bottom;
}
.titleBig{
	font-size:21px;
	font-weight:bold;
}
.header{
	background:#FFF;
}
.header #headerPanel{
	position:relative;
	max-width:980px;
	min-width:289px;
	height:90px;
	margin:0 auto;
}
.header ul{
	width:100%;
	margin-top:10px;
}
.header li{
	padding-right:5px;
	margin-right:5px;
	border-right:1px solid #CCC;
}
.header li.last{
	padding-right:0;
	margin-right:0;
	border-right:none;
}
.header li a{
	color:#38618C;
}
.header li a.selected{
	color:#BF111B;
	font-weight:bold;
}
#catLinks{
	display:inline-block;
}
#catLyb{
	display:none;
}
.header .catLybPanel{
	text-align:right;
	padding:5px 5px 0;
}
.header .catLybPanel select{
	height:28px;
	border:1px solid #0d8fd2;
	background:#FFF;
	color:#0d8fd2;
	font-size:14px;
	font-weight:bold;
}
.header .catLybPanel label{
	font-size:14px;
	margin-right:5px;
	color:#0d8fd2;
}
.header .logo{
	background: rgba(0, 0, 0, 0) url("//www.auto.bg/images/logo.svg") no-repeat scroll 0 0;
    bottom: 10px;
    height: 43px;
    left: 5px;
    margin: 0 auto;
    position: absolute;
    width: 204px;
}
.header #slogan{
	display:none;
	position:absolute;
	height:39px;
	bottom:-2px;
	left:220px;
	font-size:15px;
	font-weight:bold;
	color:#333;
}
.header .reg{
	position:absolute;
	bottom:16px;
	right:8px;
	line-height:14px;
}
.header .reg span{
	font-style:italic;
	/*color:#888;*/
	/*font-size:11px;*/
	font-family:Arial, Tahoma, Verdana, serif;
}
.topMenu{
	position:relative;
	z-index:10001;
	background: #38618C;
	-webkit-box-shadow: 0px 1px 2px 0px rgba(90, 90, 90, 0.59);
	-moz-box-shadow: 0px 1px 2px 0px rgba(90, 90, 90, 0.59);
	box-shadow: 0px 1px 2px 0px rgba(90, 90, 90, 0.59);
	top: 0;
	left: 0;
	right: 0;
	min-width: 340px;
}
.topMenu .items{
	max-width:980px;
	min-width:288px;
	margin:0 auto;
	height:40px;
	overflow:hidden;
	border-left: 1px solid #6E8EB0;
}
.topMenu .items a, .topMenu .items .clever-link{
	position:relative;
	display:block;
	float:left;
	line-height:40px;
	padding:0 20px;
	color:#FFF;
	font-size:16px;
	font-weight:bold;
	text-align:center;
	text-decoration:none;
	border-right: 1px solid #6E8EB0;
}
.topMenu .items a#menuBuy::after{
	 content: "Търсене";
}
.topMenu .items #menuSell::after{
	 content: " Добави обява";
}
.topMenu .items a#menuReviews::after{
	 content: "Авто ревюта";
}

.topMenu .items a#menuDealers::after{
	content: "Автокъщи";
}

/*.topMenu .items a#menuDealers{*/
/*	display: none;*/
/*}*/

.topMenu .items #menuMy span{
	display:inline-block;
}
.topMenu .items a.selected,
.topMenu .items a:hover,
.topMenu .items #menuMy:hover,
.topMenu .items #menuMy.selected,
.topMenu .items .clever-link.selected,
.topMenu .items .clever-link:hover{
	background: #6E8EB0; /* Old browsers */
}
.topMenu .items #menuMy{
	float:right;
	border-left: 1px solid #6E8EB0;
    cursor: pointer;
}
.topMenu .items #menuMy img{
	vertical-align:middle;
	/*vertical-align:-4px;*/
}
.topMenu .items #menuMy span{
	display:inline-block;
	margin-left:5px;
}
.topHeader{
	position:static;
	z-index:99999999;
	top:0;
	left:0;
	right:0;
	width:100%;
}
.page{
	max-width:950px;
	min-width:260px;
	margin:12px auto 0;
	padding:15px;
	background:#FFF;
	/*overflow: hidden;*/
}
ul.scheme23{
	width:100%;
}
ul.scheme23 li.leftColumn{
	width:625px;
	width:100%;
	max-width:625px;
	margin-right:25px;
	margin-bottom:10px;
	min-height:250px;
}
ul.scheme23 li.rightColumn{
	width:300px;
	float:right;
}
ul.scheme23 li.rightColumn .banner{
	margin-bottom:20px;
}
.index{
	display:block;
	table-layout:fixed;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
	width:100%;
	max-width:980px;
}
.index ul.scheme23{
	display:table-row;
	width:100%;
}
.index li.leftColumn{
	display:table-cell;
	width:auto;
	margin:0;
	max-width:625px;
	float:none;
	vertical-align:top;
}
.index li.rightColumn{
	display:table-cell;
	width:300px;
	padding-left:25px;
	float:none !important;
	vertical-align:top;
}
.carPage1Column li.rightColumn {
	display:none;
}
.carPage1Column li.leftColumn {
	max-width:none !important;
	margin-right:0 !important;
}
.carPage1Column table.dowble{
	width:100% !important;
}
.carPage1Column table.options{
	width:100% !important;
}
.carPageSmall1 table.options th{
	width:94% !important;
	padding-left:3% !important;
	padding-right:3% !important;
	float:left;
	border-left:0 !important;
	background:#f2f2f2;
}
.carPageSmall1 table.options th.secColumn{
	background:#FFF;
}
.carPageSmall1 table.dowble th, .carPageSmall1 table.dowble td{
	width:44% !important;
	padding-left:3% !important;
	padding-right:3% !important;
	float:left;
	text-align:left !important;
}
.carPageSmall1 table.dowble th{
	clear:both;
	border-left:0 !important;
}
.carPageSmall1 table.dowble tr{
	background:none !important;
	background:#f2f2f2 !important;
}
.carPageSmall1 table.dowble th.secColumn, .carPageSmall1 table.dowble td.secColumn{
	background:#FFF !important;
}
ul.scheme31{
	width:100%;
}
ul.scheme31 li.leftColumn{
	width:700px;
	margin-right:25px;
	margin-bottom:3px;
	min-height:250px;
}
ul.scheme31 li.rightColumn {
	width:225px;
}
ul.scheme31 li.rightColumn .banner{
	margin-bottom:20px;
}
ul.scheme13{
	width:100%;
}
ul.scheme13 li.rightColumn {
	width:700px;
	margin-left:25px;
	margin-bottom:3px;
	min-height:250px;
}
ul.scheme13 li.leftColumn{
	width:225px;
}
ul.scheme13 li.leftColumn.banner{
	margin-bottom:20px;
}
.resultsPage1Column li.leftColumn {
	display:none;
}
.resultsPage1Column li.rightColumn {
	margin-left:0 !important;
	width:100% !important;
}
.resultsPageNoInfo div.info div{
	margin-top:2px !important;
}
.resultsPageNoInfo div.info span{
	display:none;
}
.resultsPageNoInfo div.price{
	float:left !important;
	margin:3px 0 0 !important;
	clear:both !important;
}
.resultsPageTextBottom li.text{
	padding-left:0 !important;
	margin-top:107px;
	min-height:inherit;
}
.resultsPageTextBottom li.morePhotos{
	display:none;
}
.resultsPageTextBottom div.price{
	position:absolute;
	top:15px;
	right:10px;
}
.resultsPagePriceBottom div.price{
	position:static;
}
ul.searchBox{
	width:100%;
	padding:10px 0 8px;
	margin-bottom:25px;
	border-bottom:15px solid #e9e9e9;
}
ul.searchBox li.large{
	width:100%;
}
ul.searchBox li{
	margin-top:10px;
	width:48%;
}
ul.searchBox1Column li{
	width:100%;
}
ul.searchBox li.new{
	clear:both;
	margin-right:4%;
}
ul.searchBox li.subButton{
	width:50%;
	margin:0;
	text-align:center;
	padding-top:13px;
}
ul.searchBox li.subButton div{
	border-right:1px solid #e9e9e9;
	overflow:visible;
	text-overflow: none;
	white-space:normal;
}
ul.searchBox li.subButton span{
	display:inline-block;
	font-size:15px;
	padding:6px 5px;
	line-height:14px;
	font-weight:bold !important;
	color: #bf111b !important;
}
ul.searchBox li.subButton a.advSearchArrow{
	padding-right:22px;
	background:url(//www.auto.bg/images/ico/advSearchArrow.png) right center no-repeat;
}
ul.searchBox li div{
	margin:0 0 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
ul.searchBox select{
	width:100%;
	height:25px;
	font-size:14px;
	margin:0;
}
ul.searchBox select.ot-do{
	width:46%;
	float:left;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul.searchBox input[type="text"]{
	width:46%;
	height:25px;
	float:left;
	margin:0;
	padding:0 2%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul.searchBox span.ot-do-tire{
	overflow:hidden;
	display:inline-block;
	float:left;
	text-align:center;
	line-height:22px;
	width:8%;
}
ul.searchBox label{
	float:right5;
	margin-left:6px;
}
ul.searchBox input[type="checkbox"]{
	margin:0 5px 0 0;
	vertical-align:-1px;
}
ul.searchBox .short{
	float:left;
	width:49%;
	margin:0;
}
ul.searchBox .mr{
	margin-right:2%;
}
ul.searchBox button{
	background: #BF111B;
    width: 100%;
	color: #fff;
	border: 0;
	padding: 5px 5px 9px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	font-size: 2.4em;
	font-weight: bold;
	text-shadow: 1px 1px 1px #000000;
	margin-top:5px;
	cursor:pointer;
}
ul.searchBox button:hover{
	background: #ca3941;
}
ul.searchBox button span.button-text{
	display:block;
	margin-bottom:2px;
}
ul.searchBox button span.button-subtext{
	display:block;
	font-size:16px;
	font-weight:normal;
}
.latestAds{
	width:300px;
	margin-top:15px;
}
.latestAds .menu{
	height:28px;
	margin-bottom:5px;
}
.latestAds .menu a{
	display:block;
	float:left;
	position:relative;
	width:150px;
	line-height:28px;
	text-align:center;
	font-weight:bold;
	font-size:15.5px;
}
.latestAds .menu a.selected{
	background:#38618C;
	color:#FFF;
}
.latestAds .menu a.whole{
	width: 282px;
	padding: 0 9px;
	text-align:left;
}
.latestAds .menu a.selected img{
	position:absolute;
	bottom:-8px;
	left:50%;
	margin-left:-7px;
}
.latestAds .items a{
	display:inline-block;
	width:100%;
	color:#333;
	text-decoration:none;
	margin-top:12px;
}
.latestAds .items a img{
	float: left;
	width: 78px;
	height: 59px;
	margin-right:10px;
	object-fit: cover;

}
.latestAds .items a span{
	float:left;
	display:inline-block;
	width:210px;
	min-height:50px;
	padding-bottom:5px;
	border-bottom:3px solid #e9e9e9;
	font-style:italic;
	font-family:Arial, Tahoma, serif;
	line-height:14px;
}
.latestAds .items a:hover span{
	border-color:#38618C;
}
.latestAds .items a span strong{
	display:block;
	font-size:17px;
	margin-top:0px;
	margin-bottom:5px;
	font-style:normal;
}
.latestAds .items a span strong i{
	font-weight:normal;
	font-size:11px;
	color:#BF111B;
	text-transform:none;
	float:right;
	margin-top:-2px;
}
.latestAds .items a span i{
	text-transform:uppercase;
}
.latestAds .items a span i.info{
	width:205px;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow:ellipsis;
}
#sellAd{
	background:url(//www.auto.bg/images/sellBg.jpg) right 0 no-repeat;
	background-size:48%;
	min-height:146px;
}
.sellAdClear{
	background:none !important;
}
#sellAd .subTitle{
	font-size:19px;
	color: #C00;
	margin: 4px 0 20px;
	display: block;
	font-style:italic;
	font-family:Arial, Tahoma, serif;
}
#sellAd .price div{
	float:left;
	font-size:16px;
	color:#666;
	font-style:italic;
	font-family:Arial, Tahoma, serif;
	margin-bottom:5px;
	margin-right:15px;
}
#sellAd .price span{
	font-size:16px;
	color:#666;
	font-style:italic;
	font-family:Arial, Tahoma, serif;
	margin-bottom:5px;
}
#sellAd .price input[type="text"]{
	float:left;
	height:30px;
	width:110px;
	text-align:center;
	font-size:24px;
	font-weight:bold;
	vertical-align:middle;
	border-width:2px;
	border-color:#666;
	color:#ab0000;
}
#sellAd .price span{
	display:inline-block;
	margin-left:5px;
	line-height:30px;
}
#sellAd .control{
	clear:both;
	font-style:italic;
	font-family:Arial, Tahoma, serif;
	font-size:13px;
}
#sellAd .control button{
	margin: 8px 10px 0 0;
}
#sellAd .control div{
	display:inline-block;
	margin-top:6px;
}
.reviewsPanel{
	margin-top:24px;
}
.reviewsPanel li.rightColumn{
	padding:0 !important;
}
.reviewsPanel li.rightColumn .latestAds{
	margin:0;
}
.reviewsPanel div.bigPhoto{
	position:relative;
	border-top:5px solid #e9e9e9;
	padding-top:15px;
}
.reviewsPanel div.bigPhoto a.navR{
	position:absolute;
	z-index:10;
	bottom:50%;
	margin-bottom:-58px;
	right:15px;
	display:block;
	height:60px;
	width:60px;
	background:url(//www.auto.bg/images/ico/newsPhotoNavR.png) right center no-repeat;
	opacity:.5;
}
.reviewsPanel div.bigPhoto a.navR:hover{
	opacity:1;
}
.reviewsPanel div.bigPhoto a.navL{
	position:absolute;
	z-index:10;
	bottom:50%;
	margin-bottom:-58px;
	left:15px;
	display:block;
	height:60px;
	width:60px;
	background:url(//www.auto.bg/images/ico/newsPhotoNavL.png) left center no-repeat;
	opacity:.5;
}
.reviewsPanel div.bigPhoto a.navL:hover{
	opacity:1;
}
.reviewsPanel div.bigPhoto a.news{
	display:block;
	position:relative;
}
.reviewsPanel div.bigPhoto .titleBig{
	margin-bottom:18px;
}
.reviewsPanel div.bigPhoto img{
	width:100%;
	height:auto;
}
.reviewsPanel div.bigPhoto span{
	position:absolute;
	top:10px;
	left:10px;
	color:#FFF;
	font-size:21px;
	font-weight:bold;
	padding:3px 12px 5px;
	background:url(//www.auto.bg/images/transpBlack70.png);
}
.latestReviews{
	width:300px;
	margin-top:20px;
}
.latestReviews span.title{
	width:282px;
	background:#38618c;
	color:#FFF;
	text-align:left;
	padding:0 9px;
	/*margin-bottom:3px;*/
	display:block;
	/*float:left;*/
	position:relative;
	line-height:28px;
	font-weight:bold;
	font-size:15.5px;
}
.latestReviews span.title a{
	color:#FFF !important;
}
.latestReviews .menu{
	height:28px;
	margin-bottom:5px;
}
.latestReviews .menu a.selected{
	background:#666;
	color:#FFF;
}
.latestReviews .menu a.selected img{
	position:absolute;
	bottom:-8px;
	left:50%;
	margin-left:-7px;
}
.latestReviews .items a{
	display:inline-block;
	position:relative;
	width:100%;
	color:#333;
	text-decoration:none;
	margin-top:14px;
}
.latestReviews .items a span.image{
	float:left;
	display:block;
	width:122px;
	height:80px;
	margin-right:10px;
	overflow:hidden;
	text-align:center;
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
}
.latestReviews .items a span.arrow{
	position:absolute;
	left:-15px;
	display:none;
	width:15px;
	height:80px;
	background:url(//www.auto.bg/images/ico/reviews-arrow.png) 0 0 no-repeat;
}
.latestReviews .items a span.text{
	float:left;
	display:inline-block;
	width:168px;
	min-height:72px;
	padding-bottom:5px;
	border-bottom:3px solid #e9e9e9;
	color:#777;
	font-style:italic;
	font-family:Arial, Tahoma, serif;
	line-height:14px;
}
.latestReviews .items a:hover span.text{
	border-color:#ab0000;
	color:#222;
}
.latestReviews .items a:hover span.arrow{
	display:block;
}
a.selectedReviewIttem span.arrow{
	display:block !important;
}
a.selectedReviewIttem span.text{
	border-color:#267ec8 !important;
	color:#222 !important;
}
.latestReviews .items a span.text strong{
	display:block;
	font-size:17px;
	margin-top:0px;
	margin-bottom:5px;
	color:#ab0000;
	font-style:normal;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.latestReviews .items a:hover span.text strong{
	color:#077a0e;
}
.latestReviews .items a span.text div{
	height: 40px;
	text-overflow: ellipsis;
	overflow: hidden;
}
.latestReviews .items a span.text i{
	text-transform:uppercase;
}
.popularSearches{
	margin-top:50px;
	position:relative;
}
.popularSearches div{
	clear:both;
	font-size:14px;
	padding-right:150px;
	height:18px;
	overflow:hidden;
}
.popularSearches span{
	display:inline-block;
	margin-right:10px;
	font-weight:bold;
}
.popularSearches a#seeAllPS{
	position:absolute;
	right:0;
	top:0;
	display:block;
	padding:3px 7px 3px;
	border-radius:4px;
	background:#ab0000;
	color:#FFF;
}
.popularSearches a#seeAllPS:hover{
	text-decoration:none;
	background: #077a0e;
}
#popularSearchesALL{
	padding:15px;
	/*border-top:1px solid #DDD;*/
	margin-top:13px;
}
#popularSearchesALL ul.panel{
	width:100%;
}
#popularSearchesALL ul.panel li.marki, #popularSearchesALL ul.panel li.modeli{
	width:50%;
}
#popularSearchesALL ul.list{
	width:100%;
}

#popularSearchesALL ul.list li{
	width:118px;
	padding-top:4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

#popularSearchesALL ul.panel div{
	font-size:13px;
	font-weight:bold;
	margin-bottom:3px;
}

#popularSearchesALL a{
	font-size:12px;
	margin-left: 4px;
}

.footer{
	margin:15px auto 0;
	background:#FFF;
	max-width:980px;
	width:100%;
	min-width:290px;
}
.footer div.holder{
	padding:5px 15px 12px;
}
.footer ul.main{
	width:100%;
}
.footer li.left{
	width:100%;
	max-width:740px;
	padding-right:10px;
}
.footer li.left ul{
	width:100% !important;
}
.footer li.left li{
	width:20%;
	color:#888;
	float:right;
}
.footer li.left li.company{
	float:left !important;
	clear:none !important;
}
.footer li.left li.company .clever-link{
    font-size: 12px;
    margin:0 0 5px 15px;
}
.footerSystemLinksUL li{
	width:25% !important;
}
.footerSystemLinksULClear li{
	clear:right !important;
	width:50% !important;
}
.footer li.left li div{
	padding-right:5px;
}
.footer li.left a{
	display:inline-block;
	margin-bottom:5px;
}
.footer li.left span{
	display:inline-block;
	font-size:11px;
}
.footer li.right{
	width:188px;
	padding-left:10px;
	border-left:1px dotted #BBB;
}
.footerSocialIcons{
	clear:both;
	border:none !important;
	padding:0 !important;
	width:100% !important;
	margin-top:10px !important;
}
.footerSystemLinks{
	width:100% !important;
	max-width:none !important;
}
.footer .title{
	font-size:13px;
	font-weight:bold;
	color:#666;
	border-bottom:1px solid #dfdfdf;
	padding-bottom:5px;
	margin-bottom:5px;
}
.footer li.right div.follow{
	margin-top:10px;
}
.footer li.right a{
	float:left;
	margin-right:10px;
}
.footer li.right a:hover{
	opacity:.7;
}
.footer li.right a.facebook{
	background-image:url(//www.auto.bg/images/ico/fb.png);
}
.footer li.right a.gplus{
	background-image:url(//www.auto.bg/images/ico/gplus.png);
}
.searchPage{
}
ul#searchBoxPage{
	width:100%;
	padding:10px 0 5px;
}
ul#searchBoxPage li.large{
	width:100%;
}
ul#searchBoxPage li{
    margin-left: 1.5%;
	margin-top:10px;
	width:31.6%;
    height: 40px;
}
ul.searchBoxPage1Column li{
	width:100% !important;
	margin:10px 0 0 !important;
}
ul#searchBoxPage li.new{
	clear:both;
	margin-right:2.65%;
}
ul#searchBoxPage li.last{
	margin-left:2.55%;
}
ul#searchBoxPage li.SearchBUTTON{
	margin-top:15px;
	margin-right:0;
	float:left;
		width:64.5%;
	clear:both;
}
ul#searchBoxPage li.SearchBUTTON div{
	text-align:center;
}
ul#searchBoxPage a.advSearchArrow{
	display:inline-block;
	padding-right:22px;
	background:url(//www.auto.bg/images/ico/advSearchArrow.png) right 3px no-repeat;
}
ul#searchBoxPage li.subButton div{
	border-right:1px solid #AAA;
}
ul#searchBoxPage a.subButton{
	display:inline-block;
	text-align:center;
	font-size:15px;
	margin-top:7px;
	font-weight:bold;
	color: #bf111b;
}
ul#searchBoxPage li div{
	margin:0 0 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
ul#searchBoxPage select{
	width:100%;
	height:25px;
	font-size:14px;
	margin:0;
}
ul#searchBoxPage select.ot-do{
	width:46%;
	float:left;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul#searchBoxPage input[type="text"]{
	width:46%;
	height:25px;
	float:left;
	margin:0;
	padding:0 2%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul#searchBoxPage input[type="text"].large{
	width:100%;
}
ul#searchBoxPage span.ot-do-tire{
	overflow:hidden;
	display:inline-block;
	float:left;
	text-align:center;
	line-height:22px;
	width:8%;
}
ul#searchBoxPage label{
	margin-left:6px;
}
ul#searchBoxPage input[type="checkbox"]{
	margin:0 5px 0 0;
	vertical-align:middle;
}
ul#searchBoxPage input[type="radio"]{
	margin:0 5px 0 0;
	vertical-align:middle;
}
ul#searchBoxPage .condition{
	margin-top:6px;
}
ul#searchBoxPage .condition label{
	margin:2px 10px 0 0;
	display:inline-block;
	float:left;
	text-overflow:clip !important;
	white-space:normal !important;
}
.condition label span{
	font-family:arial, sans-serif;
	font-style:italic;
	font-weight:normal;
	color:#999;
}
ul#searchBoxPage .short{
	float:left;
	width:49%;
	margin:0;
}
ul#searchBoxPage .mr{
	margin-right:2%;
}
ul#searchBoxPage .short input[type="text"]{
	width:100%;
}
ul#searchBoxPage input[type="checkbox"]{
	vertical-align:-1px;
}
ul#searchBoxPage div span{
	color:#0099cc;
	font-weight:bold;
}
ul#searchBoxPage button{
	background-color: #bf111b;
	width: 100%;
	color: #fff;
	border: 0;
	padding: 5px 5px 9px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	font-size: 2.4em;
	font-weight: bold;
	text-shadow: 1px 1px 1px #000000;
	cursor:pointer;
}
ul#searchBoxPage button:hover{
	background-color: #ca3941;
}
ul#searchBoxPage button span.button-text{
	display:block;
	margin-bottom:2px;
}
ul#searchBoxPage button span.button-subtext{
	display:block;
	font-size:16px;
	font-weight:normal;
}
#searchTechOptions{
	margin-top:10px;
}
#searchTechOptions ul{
	width:100%;
	margin-top:10px;
	margin-bottom:10px;
	margin-left:15px;
}
.searchTechOptions2columns li{
	width:49% !important;
	margin:0 !important;
	float:left;
	clear:left;
}
.searchTechOptions2columns li.col2{
	float:right;
	clear:right;
}
.searchTechOptions2columns li.col3{
	padding-top:14px;
}
.searchTechOptions2columns li.col4{
	float:right;
	clear:right;
	padding-top:14px;
}
.searchTechOptions2columns div.legend{
	display:none;
}
.searchTechOptions1column li{
	width:100% !important;
	margin:0 !important;
	float:left;
	clear:both;
}
.searchTechOptions1column div.legend{
	display:none;
}
.searchTechOptions1column li.col2,.searchTechOptions1column li.col3,.searchTechOptions1column li.col4{
	padding-top:15px;
}
#searchTechOptions li.col1{
	width:285px;
	margin-right:14px;
}
#searchTechOptions li.col2{
	width:275px;
	margin-right:14px;
}
#searchTechOptions li.col3{
	width:180px;
	margin-right:14px;
}
#searchTechOptions li.col4{
	width:153px;
}
#searchTechOptions label{
	position:relative;
	display:inline-block;
	line-height:12px;
	padding:0 0 8px 20px;
}
#searchTechOptions input[type="checkbox"]{
	margin:0 5px 0 0;
}
#searchTechOptions i{
	position:absolute;
	top:0;
	left:0;
}
#searchTechOptions i.cbw{
	display:inline-block;
	width:14px;
	height:14px;
	background:url(//www.auto.bg/images/ico/cb.png) 0 0 no-repeat;
	margin-right:5px;
	vertical-align:-2px;
}
#searchTechOptions i.cbb{
	display:inline-block;
	width:14px;
	height:14px;
	background:url(//www.auto.bg/images/ico/cb.png) 0 -20px no-repeat;
	margin-right:5px;
	vertical-align:-2px;
}
#searchTechOptions i.cbr{
	display:inline-block;
	width:14px;
	height:14px;
	background:url(//www.auto.bg/images/ico/cb.png) 0 -40px no-repeat;
	margin-right:5px;
	vertical-align:-2px;
}
#searchTechOptions label.red{
	color:#F00000;
}
#searchTechOptions label.blue{
	color:#1681b2;
}
#searchTechOptions div.title{
	font-weight:bold;
	text-transform:uppercase;
	margin-bottom:15px;
}
#searchTechOptions div.legend{
	margin-top:20px;
	width:250px;
	border:1px solid #AAA;
	border-left-width:3px;
	border-right-width:3px;
	border-radius:3px;
	padding:10px;
}
#searchTechOptions div.legend div{
	margin-bottom:10px;
	font-weight:bold;
	text-transform:uppercase;
}
#searchTechOptions div.legend span{
	display:block;
	font-size:11px;
	margin-top:5px;
	color:#888;
}
.infoPanel{
	margin:17px 0;
	border-bottom:15px solid #e9e9e9;
	padding-bottom:15px;
	background:#FFF;
}
.infoPanel .pad{
	display:inline-block;
	width:100%;
}
.infoPanel .text{
	float:left;
	width:100%;
	max-width:393px;
	margin-bottom:5px;
}
.infoPanel .text span{
	display:block;
	margin-bottom:3px;
	font-weight:bold;
	font-size:13px;
}
.infoPanel .text span.emailLab{
	display:inline-block;
	margin-right:5px;
}
.infoPanel .text select{
	height:24px;
}
.infoPanel .button{
	float:right;
	width:210px;
	text-align:right;
	margin-top:5px;
}
.resultsNavTop{
}
.resultsNavTop ul{
	width:100%;
	display:inline-block;
}
.resultsNavTop .pages{
	float:left;
}
.resultsNavTop .sort{
	float:right;
}
.resultsNavTop .sort select{
	margin-left:3px;
	height:30px;
}
.resultsNavTop button, .resultsNavTop a {
	font-size:26px;
	line-height:26px;
	width:32px;
	padding:0 0 2px;
	margin:0 3px 0 0;
	vertical-align:middle;
	text-align: center;
}
.resultItem{
	position:relative;
	margin-top:15px;
	padding:10px 10px 7px;
	border:1px solid #DDD;
	border-bottom:2px solid #BBB;
	border-right:2px solid #BBB;
	border-radius:5px;
}
.resultItem ul{
	display:inline-block;
	width:100%;
}
.resultItem .photo{
	position:absolute;
	width:135px;
}
.resultItem .photo img{
	width:135px;
	height:102px;
    object-fit: cover;
}
.resultItem .photo a:hover img{
	opacity:.75;
}
.resultItem .text{
	float:right;
	width:100%;
	min-height:102px;
	padding-left:145px !important;
	box-sizing:border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.resultItem .text .head{
	width:100%;
	display:inline-block;
}
.resultItem .text .head .link{
	float:left;
}
.resultItem .text .head .link a{
	display:inline-block;
	font-size:17px;
	font-weight:bold;
}
.resultItem .text .head .price{
	float:right;
	font-size:18px;
	font-weight:bold;
	text-align:right;
}
.resultItem .text .info{
	margin-top:0px;
	font-style:italic;
	font-family:Arial, serif;
	color:#333;
}
.resultItem .text .info div{
	margin-top:6px;
	margin-bottom:6px;
	font-style:normal;
	font-size:13px;
}
.resultItem .text .info div strong{
	color:#333;
}
.resultItem .text div.date{
	color: #333;
	font-style: italic;
}
.resultItem .text div.date strong{
	color: #333;
}
.resultItem li.links{
	width:100%;
	display:inline-block;
	margin-top:8px;
}
.resultItem li.links a.num{
	float:left;
	display:inline-block;
	line-height:14px;
	padding:0 0 0 23px;
	background:url(//www.auto.bg/images/ico/photo-ico.png) 0 0 no-repeat;
	font-size:12px;
	text-decoration:none !important;
}
.resultItem li.links a.num:hover{
	background-position:0 -20px;
}
.resultItem li.links a.cam{
	float:left;
	display:inline-block;
	line-height:14px;
	width: 20px;
	padding:0;
	margin-right: 10px;
	background:url(//www.auto.bg/images/ico/photo-cam.png) 0 0 no-repeat;
	font-size:12px;
	text-decoration:none !important;
}
.resultItem li.links a.cam:hover{
	background-position:0 -20px;
}
.resultItem li.links a, .resultItem li.links span.clever-link{
	float:right;
}
.resultItem li.links div.control{
	float:right;
	color:#CCC;
}
.resultItem li.links div.control a{
	float:none;
}
.resultItem li.links div.control span{
	display:inline-block;
}
.resultItem li.links a.save, .resultItem li.links span.save{
	display:inline-block;
	font-weight:bold;
	color: #38618c;
}
.resultItem li.links a.delete, .resultItem li.links span.delete{
	display:inline-block;
	font-weight:bold;
	color:#F00 !important;
}
.resultItem li.morePhotos{
	width:100%;
	height:43px;
	overflow:hidden;
	margin-top:10px;
}
.resultItem li.morePhotos img{
	width:57px;
	height:43px;
    object-fit: cover;
}
.resultItem li.morePhotos a:hover img{
	opacity:.7;
}
.resultItemDeleted li.text{
	float:left;
	width:675px;
	color:#888 !important;
}
.resultItemDeleted .link a{
	color:#666;
	text-decoration:none !important;
	cursor:default;
}
.resultItemDeleted .info{
	color:#888 !important;
}
.resultItemDeleted .info strong{
	color:#888 !important;
}
.resultItemDisabled .link a{
	color:#333;
	text-decoration:none !important;
	cursor:default;
}
.resultItemDisabled .photo a img{
	opacity:1 !important;
	cursor:default;
}
.resultItemDisabled li.links a.num{
	background:url(//www.auto.bg/images/ico/photo-ico.png) 0 -40px no-repeat !important;
	color:#777;
	cursor:default;
}
/*.resultItem li.morePhotos a img{
	opacity:1 !important;
}*/
.resultItemTOP{
	/*border:1px solid #28942e;
	border-right:2px solid #28942e;
	border-bottom:2px solid #28942e;*/
}
.resultItemTOP .link a{
	/*color:#bf111b;*/
}
.resultItemTOP img.TOP{
	position:absolute;
	z-index:10;
	top:-5px;
	left:-5px;
}
.resultItemVIP img.VIP{
	position:absolute;
	z-index:10;
	top:-5px;
	left:-5px;
}
.resultsNavBottom{
	position:relative;
	margin-top:10px;
	height:30px;
	margin-top:10px;
}
.resultsNavBottom ul{
	width:100%;
	display:inline-block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.resultsNavBottom li.L{
	position:absolute;
	top:0;
	left:0;
	width:50px;
	line-height:0;
	text-align:center;

}
.resultsNavBottom li.C{
	float:left;
	width:100%;
	text-align:center;
	line-height:40px;
	line-height:0;
	padding:0 35px;
	height:33px;
	overflow:hidden;

	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.resultsNavBottom li.R{
	position:absolute;
	top:0;
	right:0;
	width:50px;
	text-align:center;
	line-height:0;
}
.resultsNavBottom li.C button, .resultsNavBottom li.C a {
	font-size:17px;
	line-height:29px;
	width:32px;
	padding:0 0 0px;
	margin:0 2px 0;
	vertical-align:middle;
	font-weight:normal;
}
.btNavArrow{
	font-size:26px !important;
	line-height:26px !important;
	width:32px !important;
	padding:0 0 2px !important;
	margin:0 !important;
	vertical-align:middle !important;
}
.btNavArrowDisabled{
	opacity:.35;
	cursor:default !important;
	background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fdfdfd), color-stop(100%, #d2d2d2)) !important;
	background-image: -webkit-linear-gradient(#fdfdfd 0%, #d2d2d2 100%) !important;
	background-image: -moz-linear-gradient(#fdfdfd 0%, #d2d2d2 100%) !important;
	background-image: -o-linear-gradient(#fdfdfd 0%, #d2d2d2 100%) !important;
	background-image: linear-gradient(#fdfdfd 0%, #d2d2d2 100%) !important;
	background-color:#f0f0f0 !important;
	color: #65b16a !important;
}
.navLinks{
	position:relative;
	border-bottom:1px solid #e9e9e9;
	padding-bottom:13px;
	margin-bottom:15px;
	line-height: 14px;
	vertical-align: middle;
}
.navLinks a,
.navLinks span{
	display:inline-block;
	line-height: 14px;
	vertical-align: middle;
	height: 18px;

}
.navLinks div.navButtons{
	position:absolute;
	top:-9px;
	right:0;
}
.navLinks div.shareLinks{
	position:absolute;
	top:-2px;
	right:0;
}
.navLinks div.shareLinks span{
	display:inline-block;
	font-size:11px;
	font-weight:bold;
}
.navLinks div.shareLinks a{
	display:inline-block;
	margin-left:10px;
	vertical-align:middle;
}
.navLinks .nextPrev{
	float: right;
}
.navLinksBottom{
	position:relative;
	border-top:4px solid #e9e9e9;
	padding-top:13px;
	border-bottom:none;
	padding-bottom:0;
	margin-bottom:0;
}
.navLinksBottom div.shareLinks{
	position:absolute;
	top:9px;
	right:0;
}
.filtersPanel{
	width:225px;
}
.filtersPanel .menu{
	height:28px;
	margin-bottom:5px;
}
.filtersPanel .menu a{
	display:block;
	float:left;
	position:relative;
	width:150px;
	line-height:28px;
	text-align:center;
	font-weight:bold;
	font-size:15.5px;
}
.filtersPanel .menu a.selected{
	background:#666;
	color:#FFF;
}
.filtersPanel .menu a.selected img{
	position:absolute;
	bottom:-8px;
	left:50%;
	margin-left:-7px;
}
.filtersPanel .filters{
	background:#f5f5f5;
	border:1px solid #d2d2d2;
	padding:10px;
	border-radius:5px;
}
.filtersPanel .filters button{
	width:100%;
}
.filtersPanel .filters button.disabledFBtn{
	filter:none;
	background:#cfcfcf;
	cursor:default;
}
.filtersPanel .filters .title{
	margin-bottom:10px;
	border-bottom:1px solid #FFF;
}
.filtersPanel .filters .title span{
	display:block;
	font-size:16px;
	border-bottom:1px solid #DDD;
	padding-bottom:10px;
}
.filtersPanel ul{
	width:100%;
}
.filtersPanel li{
	width:100%;
	margin-bottom:10px;
}
.filtersPanel div{
	margin-bottom:3px;
	font-weight:bold;
	color:#222;
	font-size:11px;
}
.filtersPanel label{
	font-weight:normal;
	margin-right:5px;
}
.filtersPanel input[type="checkbox"]{
	margin:0 5px 0 0;
	vertical-align:middle;
}
.filtersPanel select{
	width:100%;
	height:22px;
}
.filtersPanel input[type="text"]{
	float:left;
	width:82px;
	height:20px;
	background-color: #fff!important;
}
.filtersPanel select.ot-do{
	width:94px;
	float:left;
}
.filtersPanel span.ot-do-tire{
	float:left;
	display:inline-block;
	width:14px;
	line-height:20px;
	text-align:center;
}
.filtersPanel .short{
	float:left;
	width:49%;
	margin:0;
}
.filtersPanel .mr{
	margin-right:2%;
}
.filtersPanel .options{
	border-top:1px solid #ddd;
	border-bottom:1px solid #FFF;
}
.filtersPanel .options a{
	display:block;
	line-height:30px;
	border-top:1px solid #FFF;
	border-bottom:1px solid #ddd;
	text-decoration:none !important;
	padding-left:2px;
	padding-right:25px;
	text-transform:uppercase5;
	background:url(//www.auto.bg/images/ico/filters-arrow.png) right 8px no-repeat;
	font-size:12px;
}
.filtersPanel .options a:hover{
	background-color:#fafafa;
	padding-left:3px;
}
.filtersPanel .options a span{
	float:right;
	font-weight:normal;
	color:#999;
}
.filtersPanel .options a.number span{
	color:#333;
	font-weight:bold5;
}
.filtersPanel .options div{
	display:none;
	background-color:#fafafa;
	margin:-1px 0 0;
	padding:3px 3px 10px;
	font-weight:normal;
	border-bottom:1px solid #ddd;
}
.filtersPanel .options a.open{
	background-color:#fafafa;
	background-position:right -21px;
}
.filtersPanel img.traderLogo{
	width:100%;
	margin-bottom:8px;
}
ul.carPageName{
	width:100%;
	padding-bottom:16px;
	border-bottom:1px solid #e9e9e9;
	margin-bottom:15px;
}
ul.carPageName li.name{
	float:left;
}
ul.carPageName li.price{
	position:relative;
	padding-right:15px;
	margin-right:15px;
	border-right:1px solid #CCC;
}
ul.carPageName li.price a{
	display:inline-block;
	width:17px;
	height:15px;
	background:url(//www.auto.bg/images/ico/statistic.png) 0 0 no-repeat;
	margin:0 0 0 10px;
}
ul.carPageName li.price a:hover{
	opacity:.7;
}
ul.carPageName li.price a.green{
	background-position:0 -30px;
}
ul.carPageName li.price a.red{
	background-position:0 -60px;
}
ul.carPageName li.price div.statistic{
	display:none;
	position:absolute;
	z-index:101;
	top:35px;
	width:190px;
	background:#FFF;
	border:1px solid #DDD;
	padding:10px;
	box-shadow:3px 3px 5px #666;
}
ul.carPageName li.price div.statistic div{
	position:relative;
}
ul.carPageName li.price div.statistic img{
	position:absolute;
	z-index:200;
	top:-20px;
	left:11px;
}
ul.carPageName li.price a.close{
	position:absolute;
	top:-1px;
	right:0px;
	width:17px;
	height:21px;
	background:url(//www.auto.bg/images/ico/close-btn.png) 4px 4px no-repeat;
}
ul.carPageName li.price a.close:hover{
	background-position:4px -19px;
}
ul.carPageName li.price div.statistic table{
	width:100%;
	border-collapse:collapse;
	border-top:1px solid #DDD;
	margin-top:10px;
}
ul.carPageName li.price div.statistic td{
	height:20px;
	vertical-align:bottom;
	border-bottom:1px solid #DDD;
	padding-bottom:7px;
}
ul.carPageName li.price div.statistic td.price{
	font-weight:bold;
	width:40%;
	font-size:12px;
}
ul.carPageName li.price div.statistic td.offcet{
	width:23%;
	text-align:center;
}
ul.carPageName li.price div.statistic td.red{
	color:#F00;
	font-weight:bold;
}
ul.carPageName li.price div.statistic td.green{
	color:#009A07;
	font-weight:bold;
}
ul.carPageName li.price div.statistic td.date{
	width:37%;
	color:#777;
	font-size:10px;
	text-align:right;
}
ul.carPageName li.price div.statistic td.date span{
	color:#AAA;
	font-size:9px;
	display:inline-block;
}
ul.carPageName li.price div.statistic .title{
	position:relative;
	background:url(//www.auto.bg/images/ico/statistic.png) 0 0 no-repeat;
	padding-left:24px;
	font-size:13px;
	font-weight:bold;
}
#carPage .navLinks a#newSearch::after, #carPage .navLinks a#newSearch2::after{
	 content: "Ново търсене";
}
#carPage .navLinks a#corection::after, #carPage .navLinks a#corection2::after{
	 content: "Корекция на търсенето";
}
#carPage .navLinks a#results::after, #carPage .navLinks a#results2::after{
	 content: "Резултати на търсенето";
}
#carPage .navLinks a#sendAlert::after, #carPage .navLinks a#sendAlert2::after{
	 content: "Съобщи за Нередност с обявата";
}

#carPage .navLinks a#prev::after, #carPage .navLinks a#prev2::after{
	content: "Предишна обява";
}
#carPage .navLinks a#next::before, #carPage .navLinks a#next2::before{
	 content: "Следваща обява";
}
#carPage .navLinks a#prev img, #carPage .navLinks a#prev2 img{
	vertical-align: -2px;
	margin-right: 3px;
}
#carPage .navLinks a#next img, #carPage .navLinks a#next2 img{
	vertical-align: -2px;
	margin-left: 3px;
}
.callDealer .errorForm{
	color:#F00;
}
.callDealer .errorForm textarea, .callDealer .errorForm input[type="text"]{
	border-color:#F00;
}
.callDealer{
	width:280px;
	padding:7px 9px 10px;
	margin:0 0 17px;
	background:#ffffd4;
	border:1px solid #d1d3d4;
}
.callDealer .title{
	font-size:14px;
	font-weight:bold;
}
.callDealer .name{
	font-size:12px;
	margin-top:2px;
}
.callDealer a.allAds{
	display:inline-block;
	margin-top:5px;
}
.callDealer .tel{
	font-size:19px;
	font-weight:bold;
	margin-top:5px;
	padding-left:27px;
	background:url(//www.auto.bg/images/ico/phone-icon.png) 0 3px no-repeat;
}
.callDealer .email{
	position:relative;
}
.callDealer .email button{
	line-height:18px;
	margin-top:10px;
}
.callDealer .email button img{
	margin-left:10px;
}
.callDealer .email ul{
	display:none;
	width:100%;
	margin-top:7px;
}
.callDealer .email li{
	width:100%;
	margin-bottom:8px;
	font-weight:bold;
}
.callDealer .email li.button, .callDealer .email li.button button{
	margin-bottom:0;
}
.callDealer .email textarea, .callDealer .email input[type="text"]{
	display:block;
	padding:5px 3%;
	margin-top:3px;
	width:94%;
}
.callDealer .email li button{
	width:100%;
	margin-bottom:10px;
}
.callDealer .email li img.code{
	border:1px solid #AAA;
	vertical-align:middle;
	float:right;
	margin-bottom:3px;
}
.callDealer .email li span{
	display:inline-block;
	padding-top:6px;
}
.callDealer .email div.status{
	overflow:hidden;
	display:none;
	background:#FFF;
	padding:0 7px;
	line-height:23px;
	height:23px;
	border:1px solid #287F00;
	font-weight:bold;
	margin-top:8px;
	color:#287F00;
}
.callDealer .email div.statusError{
	overflow: hidden;
	display: none;
	background: #F00;
	padding: 0 7px;
	line-height: 23px;
	height: 23px;
	border: 1px solid #F00;
	font-weight: bold;
	margin-top: 8px;
	color: #FFF;
}
#callDealerInside{
	width:100%;
	display:inline-block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding:7px 9px 0;
	border: 1px solid #ddd;
	border-radius: 3px;
	background-color: #fff;
}
#callDealerInside .main{
	float:left;
	display:inline-block;
	margin-right:10px;
}
#callDealerInside .title{
	float:left;
	display:inline-block;
	margin-right:10px;
	font-size:14px;
	font-weight:bold;
}
#callDealerInside .name{
	float:left;
	display:inline-block;
	font-size:12px;
	margin-top:2px;
}
#callDealerInside .tel{
	display:inline-block;
	float:left;
	clear:left;
	font-size:21px;
	font-weight:bold;
	margin-top:5px;
	padding-left:27px;
	background:url(//www.auto.bg/images/ico/phone-icon.png) 0 3px no-repeat;
}
#callDealerInside .email{
	display:inline-block;
	float:right;
	margin-top:10px;
	width:100%;
}
#callDealerInside .email button{
	line-height:18px;
	margin-top:0px;
	white-space:normal !important;
	width:100%;
}
#callDealerInside .email ul{
	display:none;
	width:100%;
	margin-top:7px;
}
#callDealerInside .email li{
	width:100%;
	margin-bottom:8px;
	font-weight:bold;
}
#callDealerInside .email li.button, .callDealer .email li.button button{
	margin-bottom:0;
}
#callDealerInside .email textarea, .callDealer .email input[type="text"]{
	display:block;
	padding:5px 3%;
	margin-top:3px;
	width:94%;
}
#callDealerInside .email li button{
	width:100%;
	margin-bottom:10px;
}
#callDealerInside .email li img.code{
	border:1px solid #AAA;
	vertical-align:middle;
	float:right;
	margin-bottom:3px;
}
#callDealerInside .email li span{
	display:inline-block;
	padding-top:6px;
}
#callDealerInside .email div.status{
	overflow:hidden;
	display:none;
	background:#FFF;
	padding:0 7px;
	line-height:23px;
	height:23px;
	border:1px solid #287F00;
	font-weight:bold;
	margin-top:8px;
	color:#287F00;
}
#callDealerInside .email div.statusError{
	overflow: hidden;
	display: none;
	background: #F00;
	padding: 0 7px;
	line-height: 23px;
	height: 23px;
	border: 1px solid #F00;
	font-weight: bold;
	margin-top: 8px;
	color: #FFF;
}
#callDealerInside .email button img{
	margin-left:10px;
}
#callDealerInside .errorForm {
  color: #F00;
}
#callDealerInside .errorForm textarea, .callDealer .errorForm input[type="text"] {
  border-color: #F00;
}
ul.carGallerySmallStage1{
	width:100% !important;
}
ul.carGallerySmallStage1 li.smallPhotos{
	display:none;
}
ul.carGallerySmallStage1 li.bigPhoto{
	width:100% !important;
	max-width:380px;
	margin:0 auto !important;
	float:none !important;
}
ul.carGallerySmallStage1 li.bigPhoto a img.photo{
	width:100% !important;
	height:auto !important;
}
ul.carGallerySmallStage1 li.bigPhoto span{
	display:block !important;
}
ul.carGallery{
	width:625px;
	margin:0 auto !important;
	display:table;
}
ul.carGallery li.bigPhoto{
	position:relative;
	width:325px;
	margin-right:13px;
	line-height:0;
}
ul.carGallery li.bigPhoto span>span{
	display:none;
	position:absolute;
	top:10px;
	right:10px;
	color:#FFF;
	font-size:11px;
	line-height:16px;
	padding:1px 8px 1px 20px;
	background:url(//www.auto.bg/images/ico/photo.png) 6px 4px no-repeat rgba(0,0,0,0.5);
}
ul.carGallery li.smallPhotos{
	width:282px;
	line-height:0;
}
ul.carGallery li.bigPhoto a{
}
ul.carGallery li.bigPhoto img.photo{
	width:330px;
	height:222px;
}
ul.carGallery li.bigPhoto img.TOP{
	position:absolute;
	top:-4px;
	left:-4px;
}
ul.carGallery li.bigPhoto img.VIP{
	position:absolute;
	top:-4px;
	left:-4px;
}
ul.carGallery li.smallPhotos ul{
	width:282px;
}
ul.carGallery li.smallPhotos li{
	width:90px;
	margin:0 0 6px 6px;
}
ul.carGallery li.smallPhotos li a{
	width:90px;
    height:60px;
    display: block;
}
ul.carGallery li.smallPhotos li.newRow{
	clear:left;
	margin-left:0;
}
ul.carGallery li.smallPhotos img{
	width:90px;
	height: 60px;
    object-fit: cover;
}
ul.carGallery li.buttonGallery{
	width:100% !important;
	margin:0 !important;
}
ul.carGallery li.buttonGallery button{
	width:100%;
	line-height:24px;
	font-size:13px;
	padding:0;
}
.pageActions{
	display:inline-block;
	width:96%;
	padding:5px 2%;
	border-radius: 3px;
	background-color: #eee;
	margin: 10px 0 20px;
}
.pageActions a.last{
	margin:0;
	padding-right:0;
	border:none;
}

.pageActions a.save ,.pageActions span.save {
	display:inline-block;
	font-size:14px;
	line-height:30px;
	padding-right:15px;
	margin-right:10px;
	border-right:1px solid #CCC;
	padding-left:24px;
	background-position:0 6px;
	background-repeat:no-repeat;
	text-decoration:none !important;
	background-image:url('/images/ico/save-ico.png');
}
.pageActions a.save:hover .pageActions span.save:hover{
	/*background-position:0 -44px;*/
}

.pageActions a.delete, .pageActions span.delete {
	color:#D00 !important;
	display:inline-block;
	font-size:14px;
	line-height:30px;
	padding-right:15px;
	margin-right:10px;
	border-right:1px solid #DDD;
	padding-left:24px;
	background-position:0 6px;
	background-repeat:no-repeat;
	text-decoration:none !important;
	background-image:url('/images/ico/remove-btn.png');
}
.pageActions a.delete:hover , .pageActions span.delete:hover{
	/*background-position:0 -44px;*/
}


/*.pageActions .save{*/
/*	display:inline-block;*/
/*	font-size:14px;*/
/*	line-height:30px;*/
/*	padding-right:15px;*/
/*	margin-right:10px;*/
/*	border-right:1px solid #DDD;*/
/*	padding-left:24px;*/
/*	background-position:0 6px;*/
/*	background-repeat:no-repeat;*/
/*	text-decoration:none !important;*/
/*	background-image:url(//www.auto.bg/images/ico/save-ico.png);*/
/*}*/
/*.pageActions a.delete{*/
/*	color:#E00 !important;*/
/*	display:inline-block;*/
/*	font-size:14px;*/
/*	line-height:30px;*/
/*	padding-right:15px;*/
/*	margin-right:10px;*/
/*	border-right:1px solid #DDD;*/
/*	padding-left:24px;*/
/*	background-position:0 6px;*/
/*	background-repeat:no-repeat;*/
/*	text-decoration:none !important;*/
/*	background-image:url(//www.auto.bg/images/ico/remove-btn.png);*/
/*}*/

/*.pageActions a.save:hover{*/
/*	background-position:0 -44px;*/
/*}*/
.pageActions a.print{
	display:inline-block;
	font-size:14px;
	line-height:30px;
	padding-right:15px;
	margin-right:10px;
	border-right:1px solid #DDD;
	padding-left:24px;
	background-position:0 6px;
	background-repeat:no-repeat;
	text-decoration:none !important;
	background-image:url(//www.auto.bg/images/ico/print-ico.png);
}
.pageActions a.print:hover{
	background-position:0 -44px;
}
.pageActions .ico{
	display:inline-block;
	/*vertical-align:-4px;*/
	margin-left:6px;
}
.pageActions .ico:hover{
	opacity:.7;
}
.pageActions span.shares{
	float:right;
	display:inline-block;
	margin-top:6px;
}
.pageActions #shareLink::after{
	content: "Сподели в   ";
}
.carData .title{
	font-size:16px;
	font-weight:bold;
	margin-bottom:8px;
}
.carData table.dowble{
	width:625px;
	border-collapse:collapse;
	border-bottom:1px solid #DDD;
}
.carData table.dowble th,.carData table.dowble td{
	width:142px;
	padding:5px 10px;
	font-size:12px;
}
.carData table.dowble th.secColumn{
	border-left: 15px solid white;
}
.carData table.dowble th{
	text-align:left;
	font-weight:bold;
}
.carData table.dowble td{
	text-align:right;
}
.carData table.dowble tr.odd{
	background:#f2f2f2;
}
.carData table.dowble tr:hover{
	background:#e0e0e0;
}
.carData .moreInfo{
	margin:20px 0 30px;
	padding:0 10px;
	font-size:13px;
}
.carData table.options{
	width:625px;
	border-collapse:collapse;
	border-bottom:1px solid #DDD;
	margin-bottom:30px;
}
.carData table.options th,.carData table.options td{
	width:305px;
	padding:5px 10px;
	font-size:12px;
}
.carData table.options th.secColumn{
	border-left: 15px solid white;
}
.carData table.options th{
	text-align:left;
	font-weight:normal;
}
.carData table.options td{
	text-align:right;
}
.carData table.options tr.odd{
	background:#f2f2f2;
}
.carData table.options tr:hover{
	background:#e0e0e0;
}
.galleryContainer{
	visibility:hidden;
	position:fixed;
	z-index:10000000;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:#222222;
	width:100%;
	height:100%;
}
.galleryCloseBtn{
	position:absolute;
	z-index:99999999;
	top:10px;
	right:10px;
	height:26px;
	width:26px;
}
.galleryCloseBtn a{
	display:block;
	height:26px;
	width:26px;
	background:url(//www.auto.bg/images/ico/close-button.png) 0 0 no-repeat;
}
.loginPage1Column li.left, .loginPage1Column li.right{
	width:100% !important;
}
.loginPage1Column li.right{
	width:100% !important;
	border-top:10px solid #DDD;
	padding-top:15px;
	margin-top:10px;
}
.loginPage1Column li.right{
	text-align:center;
}
.loginPage1Column .container{
	border-right:none !important;
	padding:0 !important;
}
.loginPage1Column li.lab, .loginPage1Column li.lab2{
	width:100% !important;
	margin-bottom:0px !important;
	line-height:26px !important;
}
.loginPage1Column li.form{
	width:100% !important;
	clear:both !important;
	margin-bottom:4px !important;
}
.loginPage1Column button{
	max-width:none !important;
	margin-top:12px !important;
}
#loginPage {
	width: 100%;
	max-width: 400px;
	padding: 15px 20px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul.signin{
	width:100%;
	margin-top:20px;
}
ul.signin li.left{
	width:100%;
}
ul.signin li.left .container{
	/*border-right:1px solid #DDD;
	padding:0 10% 0 0;*/
	padding: 0;
}
ul.signin li.right{
	width:50%;
}
ul.signin li.right .container{
	padding:0 0 0 10%;
}
ul.signin ul.forms{
	width:100%;
}
ul.signin ul.forms li.lab{
	width:38%;
	line-height:normal;
	margin-bottom: 3px;
	font-weight: bold;
	text-align: right;
	padding-right: 10px;
	padding-top: 6px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul.signin ul.forms li.lab2{
	line-height:13px;
}
ul.signin ul.forms li{
	width:100%;
	margin-bottom:12px;
}
ul.signin ul.forms li.form{
	width:62%;
}
ul.signin ul.forms input[type="text"], input[type="password"], select, textarea{
	width:100%;
	padding:0;
	height:24px;
}
ul.signin ul.forms strong{
	font-size:15px;
}
ul.signin ul.forms button{
	margin-top:5px;
	padding-left:2% !important;
	padding-right:2% !important;
	width:100%;
	max-width:240px;
}
ul.signin ul.forms a.social:hover{
	opacity:.85;
}
ul.signin ul.forms label{
	display: inline-block;
	margin-right: 10px;
}
ul.signin ul.forms input[type="radio"]{
	margin: 0 5px 0 0;
	padding: 0;
	vertical-align: -2px;
}
ul.signin ul.forms .agree{
	font-size: 12px;
	display: inline-block;
	margin-top: 15px;
	margin-bottom: 5px;
}
ul.signin ul.forms .agree a{
	color: #09F;
	text-decoration: none;
}
ul.signin ul.forms .agree a:hover{
	text-decoration: underline;
}
.publikuvaneFasha{
	position:relative;
	background:#f8f8f8;
	border-top:1px solid #CCC;
	border-bottom:1px solid #CCC;
	padding:10px 15px;
	text-align:center;
	font-size:14px;
}
.publikuvaneFasha strong.blue{
	color:#267ecb;
	font-size:16px;
}
.publikuvaneFasha img{
	position:absolute;
	bottom:-21px;
	left:50%;
	margin-left:-20px;
}
.publikuvaneFasha a{
	display:inline-block;
	margin:0 10px 3px;
}
ul#publikuvaneContacts{
	width:100%;
}
ul#publikuvaneContacts li.first{
	margin-left:0;
	margin-right:2.65%;
}
ul#publikuvaneContacts li.last{
	margin-left:2.55%;
}
ul#publikuvaneContacts li{
	width:31.6%;
	line-height:20px;
}
ul.publikuvaneContacts1Column li{
	width:100% !important;
	margin:10px 0 0 !important;
}
ul#publikuvaneContacts li .blueTitle{
	color:#0099cc;
	font-weight:bold;
}
ul#publikuvaneContacts li input[type="text"], input[type="password"]{
	width:100%;
	padding:0 2%;
	height:24px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul#publikuvaneContacts li textarea{
	width:100%;
	padding:0 2%;
	height:77px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
ul#publikuvaneContacts label{
	display:inline-block;
	margin:9px 0 6px 0;
}
ul#publikuvaneContacts input[type="checkbox"]{
	margin:0 5px 0 0;
}
ul#publikuvaneContacts button{
	width:100%;
	white-space:normal;
	line-height:20px;
	padding:13px 10px 12px;
}
ul#publikuvaneContacts a{
	display:inline-block;
}
ul#publikuvaneContacts label{
	line-height:14px;
	margin-top:15px;
}
ul#publikuvaneContacts div{
	font-weight:bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.publikuvaneSendButton{
	margin-top:20px;
	font-size:13px;
}
.publikuvaneSendButton label{
	display:inline-block;
}
.publikuvaneSendButton input[type="checkbox"]{
	margin:0 5px 0 0;
	vertical-align:-2px;
}
.publikuvaneSendButton button{
	width:100%;
	margin-top:10px;
}
.publikuvaneSendButton .note{
	font-style:italic;
	margin-top:10px;
	font-family:Arial, serif;
}
.publikuvaneSendButton .note span{
	font-weight:bold;
	color:#267ecb;
}
.regAdvantages {
	font-size:13px;
	line-height:17px;
}
.regAdvantages ul{
	margin-top:20px;
	list-style:outside disc;
	padding-left:19px;
}
.regAdvantages li{
	margin-bottom:2px !important;
}
.regAdvantages div.regLink{
	font-size:14px !important;
	margin-top:10px;
	color:#267ec8;
}
.regAdvantages div.regLink strong{
	font-size:14px !important;
}
.regAdvantages strong{
	font-size:inherit !important;
}
#showPubOptions{
	display:none;
}
#sellTechOptions{
	margin-top:10px;
}
#sellTechOptions ul{
	width:100%;
	margin-top:10px;
	margin-bottom:10px;
}
#sellTechOptions input[type="checkbox"]{
	margin:0 5px 0 0;
	vertical-align:-2px;
	position:absolute;
	top:0;
	left:0;
}
.sellTechOptions2columns li{
	width:49% !important;
	margin:0 !important;
	float:left;
	clear:left;
}
.sellTechOptions2columns li.col2{
	float:right;
	clear:right;
}
.sellTechOptions2columns li.col3{
	padding-top:14px;
}
.sellTechOptions2columns li.col4{
	float:right;
	clear:right;
	padding-top:14px;
}
.sellTechOptions1column li{
	width:100% !important;
	margin:0 !important;
	float:left;
	clear:both;
}
.sellTechOptions1column div.legend{
	display:none;
}
.sellTechOptions1column li.col2,.sellTechOptions1column li.col3,.sellTechOptions1column li.col4{
	padding-top:15px;
}
#sellTechOptions li.col1{
	width:285px;
	margin-right:14px;
}
#sellTechOptions li.col2{
	width:275px;
	margin-right:14px;
}

#sellTechOptions li.col3{
	width:180px;
	margin-right:14px;
}
#sellTechOptions li.col4{
	width:168px;
}
#sellTechOptions label{
	position:relative;
	display:inline-block;
	line-height:12px;
	padding:0 0 8px 20px;
}
#sellTechOptions input[type="checkbox"]{
	margin:0 5px 0 0;
}
#sellTechOptions div.title{
	font-weight:bold;
	text-transform:uppercase;
	margin-bottom:15px;
}
.pubSnimki a.add{
	display:block;
	width:115px;
	height:56px;
	padding-top:20px;
	background:url(//www.auto.bg/images/noPhotoBig.png) 0 0 no-repeat;
	background-size:contain;
	text-align:center;
	font-weight:bold;
	font-size:14px;
	line-height:14px;
	text-decoration:underline;
}
.pubSnimki a.add:hover{
	text-decoration:none;
}
.pubSnimki ul{
	width:100%;
}
.pubSnimki li{
	width:115px;
	margin-right:10px;
	margin-bottom:10px;
}
.pubSnimki img{
	width:115px;
	height:76px;
}
.pubSnimki .title{
	font-size:12px;
	margin-bottom:10px;
}
.newsHeader{
	width:100%;
	position:relative;
}
.newsHeader .titleBig{
	font-size:16px;
}
.newsHeader .search{
	position:absolute;
	top:-8px;
	right:-8px;
}
.newsHeader select{
	height:26px;
	width:180px;
	margin:0 0 0 5px;
	text-transform:uppercase;
}
.newsHeader button{
	line-height:22px;
	width:150px;
	vertical-align:0px;
	margin:0 0 0 5px;
}

.newsListItem{
	margin-top:15px;
	border-bottom:4px solid #e9e9e9;
	padding-bottom:15px;
}
.newsListItem li.image{
	float:left;
	width:230px;
	height:131px;
	margin-right:20px;
}
.newsListItem li.text{
	float:left;
	width:700px;
}
.newsListItem img{
	width:230px;
	height:131px;
}
.newsListItem li.text a{
	display:inline-block;
	font-size:18px;
	font-weight:bold;
	margin-bottom:5px;
}
.newsListItem li.text .statia{
	line-height:16px;
	font-size:13px;
}
.newsListItem li.text .date{
	margin-top:5px;
	color:#888;
}
.newsListItem li.text .date strong{
	color:#666;
}
.resultsNavBottomNews ul{
	width:950px;
}
.resultsNavBottomNews li.C{
	width:850px;
}
.article{
	width:625px;
	margin:0 auto;
	padding-bottom:30px;
	font-size:14px;
	line-height:21px;
}
.article .imageInfo{
	border-bottom:2px solid #CCC;
	padding-bottom:0px;
	padding-bottom:10px;
	padding-left:38px;
	padding-top:3px;
	background:url(//www.auto.bg/images/ico/comas.png) 0 5px no-repeat;
	margin-bottom:15px;
	font-size:15px;
	font-weight:bold;
	line-height:18px;
}
.articleTitle{
	padding:15px 0 0;
}
.articleDate {
	font-size:16px;
	margin-top:5px;
	margin-bottom:30px;
}

.myMenu {
	background:#f5f5f5;
	border:1px solid #d2d2d2;
	padding:12px 10px 20px;
	width:205px;
	border-radius:5px;
}
.myMenu .wellcome{
	display:block;
	width:205px;
	height:20px;
	padding-bottom:10px;
	border-bottom:1px solid #dddddd;
}
.myMenu .wellcome span{
	float:left;
	font-size:15px;
	font-weight:bold;
}
.myMenu .wellcome a{
	display:inline-block;
	float:right;
	margin-top:2px;
}
.myMenu .user{
	border-top:1px solid #FFF;
	border-bottom:1px solid #dddddd;
	padding-top:10px;
	padding-bottom:5px;
}
.myMenu .user ul{
	width:215px;
}
.myMenu .user li.photo img{
	width:40px;
	box-shadow:2px 2px 5px #999;
}
.myMenu .user li.name{
	font-size:13px;
	font-weight:bold;
	width:207px /*155*/
}
.myMenu .user li.name span{
	display:block;
	margin-top:2px;
	color:#777;
	font-size:10px;
	font-weight:normal;
}
.myMenu .items{
	border-bottom:1px solid #FFF;
}
.myMenu .items a{
	display:block;
	line-height:30px;
	border-top:1px solid #FFF;
	border-bottom:1px solid #dddddd;
	text-decoration:none !important;
	padding-right:20px;
	font-size:13px;
	background-image:url(//www.auto.bg/images/ico/userMenuArrow3.png);
	background-position:right 5px top 8px;
	background-repeat:no-repeat;
}
.myMenu .items a.selected{
	font-weight:bold;
	background-position: right 5px top -57px !important;
}
.myMenu .items a span{
	float:right;
	margin:1px 5px 0 0;
	color:#6e8eb0;
	font-weight:normal;
	font-size:11px;
}
.myMenu .items a:hover{
	background-color:#fafafa;
	background-position:right 5px top 8px;
}
.myMenu .items a:hover span{
	color:#333;
}
#subMenu {
	display:none;
	max-width:980px;
	margin:0 auto;
	position:relative;
}
.myMenuType2 {
	position:absolute;
	z-index:-1;
	top:0px;
	right:0px;
	box-shadow:0px 3px 10px #666;
	box-shadow:0px 3px 10px rgba(0,0,0,.75);
	background:#FFF;
	padding:12px 10px 20px;
	width:100%;
	max-width:180px;
	box-sizing:border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.myMenuType2 .wellcome{
	display:block;
	width:100%;
	height:20px;
	padding-bottom:10px;
	border-bottom:1px solid #155284;
}
.myMenuType2 .wellcome span{
	float:left;
	font-size:15px;
	font-weight:bold;
	color:#FFF;
}
.myMenuType2 .wellcome a{
	display:inline-block;
	float:right;
	margin-top:2px;
}
.myMenuType2 .user{
	border-bottom:1px solid #c2cad3;
	padding-bottom:5px;
}
.myMenuType2 .user ul{
	width:175px;
}
.myMenuType2 .user li.photo img{
	width:40px;
	box-shadow:2px 2px 5px #999;
}
.myMenuType2 .user li.name{
	font-size:13px;
	font-weight:bold;
	width:137px;
	color:#333;
}
.myMenuType2 .user li.name span{
	display:block;
	margin-top:2px;
	font-size:10px;
	font-weight:normal;
	color:#6e8eb0;
}
.myMenuType2 .myItems{
	/*border-bottom:1px solid #5fa663;*/
}
.myMenuType2 .myItems > span{
	display:block;
	line-height:30px;
	/*border-top:1px solid #5fa663;*/
	border-bottom:1px solid #c2cad3;
	text-decoration:none !important;
	padding-right:20px;
	font-size:13px;
	background-image:url(//www.auto.bg/images/ico/userMenuArrow3.png);
	background-position: right 5px top -57px;
	background-repeat:no-repeat;
	color:#333;
}
.myMenuType2 .myItems > span.selected{
	font-weight:bold;
	background-position: right 5px top 8px;
}
.myMenuType2 .myItems > span.selected > span{
	font-weight:bold;
}
.myMenuType2 .myItems > span > span{
	float:right;
	margin:1px 5px 0 0;
	color:#6e8eb0;
	font-weight:normal;
	font-size:11px;
}
.myMenuType2 .myItems > span:hover{
	background-color:#fafafa;
	background-position: right 5px top 8px;
	color:#333;
	font-weight: bold;
}
.myMenuType2 .myItems > span:hover > span{
	color:#333;
}
.filterItem{
	width:100%;
	box-sizing:border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	display:inline-block;
	position:relative;
	margin-top:15px;
	padding:10px 10px 7px;
	border:1px solid #DDD;
	border-bottom:2px solid #BBB;
	border-right:2px solid #BBB;
	border-radius:5px;
}
.filterItem:hover{
	background:#F6F6F6;
}
.filterItem ul{
	display:inline-block;
	width:100%;
}
.filterItem li.text{
	float:left;
	width:100%;
	max-width:350px;
	margin-right:15px;
	margin-bottom:10px;
}
.filterItem li.text div{
	line-height:15px;
}
.filterItem li.text span{
	display:inline-block;
}
.filterItem li.text span.filterName{
	display:block;
	margin-bottom:15px;
	text-transform:uppercase;
}
.filterItem li.controls{
	float:right;
	width:100%;
	max-width:225px;
	margin:5px 0 5px;
}
.filterItem li.controls button{
	white-space:normal;
	line-height:18px;
	width:100%;
	padding:11px 3%;
	text-align:center;
}
.filterItem div.links{
	margin-top:12px;
}
.filterItem div.links span{
	display:inline-block;
}
.filterItem div.links a{
	font-weight:bold;
}
.filterItem div.links a.delete, .filterItem div.links span.delete{
	display:inline-block;
	color:#F00;
}
.settings{
	margin-top:17px;
}
.settings div.title{
	font-size:15px;
	font-weight:bold;
	margin-bottom:15px;
}
ul.userSettings{
	position:relative;
	width:700px;
	background5:#EEE;
}
ul.userSettings li.left{
	width:340px;
	margin-right:20px;
}
ul.userSettings li.left ul{
	width:340px;
}
ul.userSettings li.left li.lab{
	width:70px;
	padding-top:2px;
	line-height:11px;
}
ul.userSettings li.left li{
	width:340px;
	margin-bottom:10px;
}
ul.userSettings li.left input[type="text"]{
	width:240px;
	padding:0 10px;
	height:25px;
}
ul.userSettings li.left select{
    width:100%;
	height:25px;
	font-size:14px;
	margin:0;
}
ul.userSettings li.right{
	width:340px;
}
ul.userSettings ul.addPhoto{
	width:340px;
}
ul.userSettings ul.addPhoto li.left{
	width:60px;
	margin-right:15px;
}
ul.userSettings ul.addPhoto li.left a{
	color:#F00;
	font-weight:bold;
	font-size:11px;
}
ul.userSettings ul.addPhoto li.left img{
	width:60px;
	height:60px;
}
ul.userSettings ul.addPhoto li.right{
	width:265px;
}
ul.userSettings ul.addPhoto li.right input[type="file"]{
	width:100%;
}
ul.userSettings ul.addPhoto li.right div.note{
	font-size:11px;
	margin-top:5px;
}
ul.userSettings button{
	position:absolute;
	bottom:12px;
	right:0;
	float:right;
	margin:0;
}
.upLine{
	border-top:15px solid #e9e9e9;
	margin:25px 0 0;
}
.adsListType a{
	/*display:inline-block;*/
	padding-top:4px;
	margin-right:10px;
	margin-bottom:6px;
	font-size:15px;
}
.adsListType a.selected{
	font-weight:bold;
	cursor:default;
	text-decoration:none !important;
}
.adsListType a.selected i{
	/*display:inline-block;*/
	padding-right:13px;
	background:url(//www.auto.bg/images/ico/myAdsListArrow.png) right 8px no-repeat;
}
.adsListType a i{
	font-style:normal;
}
.adsListType a span{
	font-weight:normal;
}
.myAdsPage1Column li.rightColumn{
	display:none;
}
.myAdsPage1Column li.leftColumn  {
	margin-left:0 !important;
	width:100% !important;
}

.myAdsPageNoInfo div.info div{
	margin-top:2px !important;
}
.myAdsPageNoInfo div.info span{
	display:none;
}
.myAdsPageNoInfo div.price{
	float:left !important;
	margin:3px 0 0 !important;
	clear:both !important;
}
.myAdsPageTextBottom li.text{
	padding-left:0 !important;
	margin-top:107px;
	min-height:inherit;
}
.myAdsPageTextBottom li.morePhotos{
	display:none;
}
.myAdsPageTextBottom div.price{
	position:absolute;
	top:15px;
	right:10px;
}
.myAdsPagePriceBottom div.price{
	position:static;
}
.myFiltersPage1Column li.rightColumn{
	display:none;
}
.myFiltersPage1Column li.leftColumn  {
	margin-left:0 !important;
	width:100% !important;
}

.mySettingsPage1Column li.rightColumn{
	display:none;
}
.mySettingsPage1Column li.leftColumn  {
	margin:0 !important;
	width:100% !important;
}
.mySettingsPage1Column ul.userSettings{
	width:100%;
}
.mySettingsPage1Column ul.userSettings li.left{
	width:100%;
	max-width:400px;
	margin-right:0;
}
.mySettingsPage1Column ul.userSettings li.left ul{
	width:100%;
}
.mySettingsPage1Column ul.userSettings li.left li.lab{
	padding-top:2px;
	margin-bottom:5px;
}
.mySettingsPage1Column ul.userSettings li.left li{
	clear:both;
	width:100%;
}
.mySettingsPage1Column ul.userSettings li.left input[type="text"]{
	box-sizing:border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	width:100%;
	padding:0 10px;
}
.mySettingsPage1Column ul.userSettings li.right{
	width:auto;
	float:right;
}
.mySettingsPage1Column ul.userSettings button{
	position:relative;
	float:right;
	margin:29px 0 0;
}
.mySettingsPage1ColumnShort ul.userSettings li.right{
	width:auto;
	float:left;
}
.mySettingsPage1ColumnShort ul.userSettings button{
	margin:20px 0 0 !important;
}
.callTrader{
	display:inline-block !important;
	margin-top:10px;
	margin-bottom:10px;
}
.callTrader .addres{
	margin-top: 6px;
	margin-bottom: 6px;
}
.hint {
	position:absolute;
	/*bottom:-53px;*/
	left:0;
	margin-top:13px;
	max-width:300px;
	background:#c4f0ff;
	border:1px solid #999;
	border-radius:4px;
	padding:5px 7px;
	font-weight:normal !important;
	color:#333;
	font-size:11px;
	line-height:14px;
	overflow:visible !important;
	text-overflow:clip !important;
	white-space:normal !important;
	box-shadow:2px 2px 3px #666;
}
.hint img{
	position:absolute;
	top:-10px;
	left:20px;
}
.width15pr {width:15% !important;}
.colorOptions{
	display:block;
}
.colorOptions label{
	position:relative;
	display:inline-block;
	line-height:12px;
	padding:0 0 2px 20px;
}
.colorOptions i{
	position:absolute;
	top:0;
	left:0;
}
.colorOptions i.cbw{
	display:inline-block;
	width:12px;
	height:12px;
	background:url(//www.auto.bg/images/ico/cb-small.png) 0 0 no-repeat;
	margin-right:5px;
	vertical-align:-2px;
}
.colorOptions i.cbb{
	display:inline-block;
	width:12px;
	height:12px;
	background:url(//www.auto.bg/images/ico/cb-small.png) 0 -20px no-repeat;
	margin-right:5px;
	vertical-align:-2px;
}
.colorOptions i.cbr{
	display:inline-block;
	width:12px;
	height:12px;
	background:url(//www.auto.bg/images/ico/cb-small.png) 0 -40px no-repeat;
	margin-right:5px;
	vertical-align:-2px;
}
.colorOptions label.red{
	color:#F00000;
}
.colorOptions label.blue{
	color:#38618c;
}
.phonePopup {
	position:absolute;
	/*bottom:-53px;*/
	left:0;
	margin-top:13px;
	max-width:300px;
	background:#c4f0ff;
	border:1px solid #999;
	border-radius:4px;
	padding:5px 7px;
	font-weight:normal !important;
	color:#333;
	font-size:11px;
	line-height:14px;
	overflow:visible !important;
	text-overflow:clip !important;
	white-space:normal !important;
	box-shadow:2px 2px 3px #666;
}
.phonePopup img{
	position:absolute;
	top:-10px;
	left:20px;
}

.userPayment{
	font-size:14px;
}
.totalSum{
	float:right;
	display:inline-block;
	font-size:28px;
	font-weight:normal;
}
.sumItem{
	display:inline-block;
	width:100%;
	border-bottom:1px solid #DDD;
	padding-bottom:13px;
	margin-bottom:10px;
}
.sumItem span.date{
	float:left;
	color:#999;
	line-height:18px;
}
.sumItem span.sum{
	float:right;
	display:inline-block;
	font-size:15px;
	line-height:18px;
	text-align:right;
}

/* UPLOAD START*/
.imgplupload #selectfiles {
	color: #000;
	display: block;
	float: left;
	width: 68px;
	height: 68px;
	padding: 5px 0 0 5px;
	text-align: center;
	margin-bottom: 8px;
	margin-right: 8px;
}

.imgplupload .placeholder {
	border: 1px solid #d4d4d4;
	border-radius: 4px;
	color: #000;
	display: block;
	float: left;
	width: 68px;
	height: 68px;
	padding: 5px 0 0 5px;
	text-align: center;
	margin-bottom: 8px;
	margin-right: 8px;
	position: relative; z-index: 0;
}



.imgplupload .placeholder span.num, .imgplupload #selectfiles span.num {
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	margin-top: -10px;
	margin-left: -10px;
	width: 20px;
	line-height: 20px;
	background: #BBB;
	border-radius: 15px;
	color: #FFF !important;
	font-size: 11px;
	font-style: normal;
}

.imgplupload #container{
  height: 100px;
}

.imgplupload #selectfiles div{
    font-weight: bold;
    margin-top: 22px;
}

.imgplupload .filelist .preview {
	border: 1px solid #d4d4d4;
    color: #000;
    cursor: move;
    display: block;
    float: left;
    height: 68px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 5px 0 0 5px;
    position: relative;
    width: 68px;
}

.imgplupload .filelist .preview.imgplupload_error {
  border: 1px solid red !important;
}

.imgplupload .filelist .preview .img {
  width: 68px;
  height: 68px;
}

.imgplupload .filelist .preview .processing {
  width: 16px;
  height: 16px;
  background: url("//www.auto.bg/images/uplloader.gif") no-repeat scroll center center transparent;
  left: 50%;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  margin-left: -8px;
}


.imgplupload .delete-pic {
  background: url("//www.auto.bg/images/upldel.gif") no-repeat scroll 0 0 transparent;
  height: 16px;
  right: -8px;
  top: -5px;
  width: 16px;
  overflow: hidden;
  text-indent: -1000em;
  position: absolute;
}


.imgplupload .rotate-pic {
	background: url("//www.auto.bg/images/photo_rot.png") no-repeat scroll 0 0 transparent;
	height: 16px;
	left: -3px;
	top: -3px;
	width: 16px;
	overflow: hidden;
	text-indent: -1000em;
	position: absolute;
}


.imgplupload div.main {
    background-color: #000000;
    color: #FFFFFF;
    height: 17px;
    margin-left: 1px;
    margin-right: 1px;
    opacity: 0.7;
    position: absolute;
    text-align: center;
    top: 67px;
    left: 6px;
    width: 103px;
}
/* UPLOAD END*/
.sendAdByEmail{
	display:none;
	margin-top:-15px;
	position:relative;
}
.sendAdByEmail div.title{
	color:#333;
	font-size:15px;
	font-weight:bold;
	margin-top:10px;
	margin-bottom:13px;
	color:#0086cf;
}
.sendAdByEmail div.title img{
	margin-right:6px;
	vertical-align:-2px;
}
.sendAdByEmail div.lab{
	color:#333;
	font-size:12px;
	margin-top:10px;
	margin-bottom:5px;
	font-weight:bold;
}
.sendAdByEmail input[type="text"]{
	height:24px;
	width:100%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.sendAdByEmail .subject{
	font-weight:bold;
}
.sendAdByEmail textarea{
	width:100%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.sendAdByEmail img.antispam{
	border:1px solid #999;
}
.sendAdByEmail button{
	width:100%;
	margin-top:20px;
	margin-bottom:8px;
}
.sendAdByEmail a.close{
	position:absolute;
	z-index:500;
	top:13px;
	right:13px;
	width:17px;
	height:21px;
	background:url(//www.auto.bg/images/ico/close-btn.png) 4px 4px no-repeat;
}
.statusShareBox{
	overflow:hidden;
	display:none;
	background:#FFF;
	padding:0 7px;
	line-height:23px;
	height:23px;
	border:1px solid #287F00;
	font-weight:bold;
	margin-top:8px;
	color:#287F00;
	margin-bottom: 10px;
}
.sendAdByEmail .statusError{
	overflow: hidden;
	display: none;
	background: #F00;
	padding: 0 7px;
	line-height: 23px;
	height: 23px;
	border: 1px solid #F00;
	font-weight: bold;
	margin-top: 8px;
	color: #FFF;
	width: 90%;
}
.sendAdByEmail a.close:hover{
	background-position:4px -19px;
}
.sendAdByEmail .errorForm {
  color: #F00;
  border-color: #F00;
}
#last_adverts_notepad_div, #last_adverts_notepad_img {
	display: none;
}
.banner-megabord {
	margin: 10px auto 0 auto;
	width: 100%;
	display: block;
}
.banner-megabord-small {
	margin-top: 10px;
	width: 100%;
	display: none;
}
#juicebox-container{
	/*padding-right:320px;*/
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.galleryBanner{
	position:absolute;
	z-index:99999999;
	top:10px;
	right:10px;
}
.galleryCloseBtn{
	position:absolute;
	z-index:99999999;
	top:10px;
	right:10px;
	height:26px;
	width:26px;
}
.bannerUnderNavBottom {
	margin-top: 10px;
	text-align: right;
	clear:both;
}
.bannerUnderNavBottom760 {
	display: none;
}
.sticky_banner {
	text-align: center;
	position: sticky;
	top: 20px;
}
.btvnews-block {
	background:#f5f5f5;
	border:1px solid #d2d2d2;
	padding:10px;
	margin-bottom: 10px;
	border-radius:5px;
}
.btvnews-block .btvnews-block-title {
	font-family:Tahoma, Arial, serif;
	font-size:16px;
	font-weight:bold;
	margin-bottom:10px;
	border-bottom:1px solid #FFF;
	color:#000000;
}
.btvnews-block .btvnews-block-title span {
	display:block;
	border-bottom:1px solid #DDD;
	padding-bottom:10px;
}
.btvnews-block .btvnews-block-item {
	display:block;
	margin-bottom:10px;
	border-bottom:1px solid #FFF;
	text-decoration:none;
}
.btvnews-block .btvnews-block-item img {
	display:block;
	width:100%;
}
.btvnews-block .btvnews-block-item span {
	display:block;
	border-bottom:1px solid #DDD;
	padding:10px 0;
	font-family:Tahoma, Arial, serif;
	font-size:13px;
	font-weight:normal;
	color:#000000;
}
.btvnews-block .btvnews-block-item:hover {
	opacity:0.5;
	text-decoration:none;
}
.btvnews-block .btvnews-block-item:first-of-type {
	display:none;
}
.btvnews-block .btvnews-block-item:last-of-type {
	margin-bottom:-;
	border-bottom:none;
}
.btvnews-block .btvnews-block-item:last-of-type span {
	border-bottom:none;
	padding-bottom:0;
}
.show_mobile_banners,
.show_mobile_banners1000{
	display:none;
}

.aCity {
	display:grid;
	grid-template-columns:repeat(auto-fit, 170px);
	grid-gap: 1rem;
	justify-content:space-between;
}



.aCity a {
	display: block;
	position: relative;
	/*width: 182px;*/
	height: 65px;
	border: 1px solid #DDD;
	border-bottom: 2px solid #BBB;
	border-right: 2px solid #BBB;
	border-radius: 5px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	text-align: center;
	transition: border-color .3s;
}

.aCity a.more {
	display: none;
}
.aCity a:hover {
	text-decoration: none;
	border-color: #38618C;
}
.aCity a:hover i{
	color: #333;
}
.aCity a span{
	display: block;
	margin-top: 10px;
	padding: 0 10px;
	font-size: 16px;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.aCity a i{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 10px;
	font-style: normal;
	font-size: 14px;
	color: #999;
}
.aCity a i em{
	font-style: normal;
}
.aCity .drugi {
	width: 39.2%;
	margin-right: 0;
}
.aCity .drugi a {
}

/*RESPONSIVE DESIGN*/
@media (max-width: 1000px) {
	.show_mobile_banners1000{
		display:block;
		margin-bottom:15px;
		text-align: center;
	}
	#footerSocialIcons{
		clear:both;
		border:none;
		padding:0;
		width:100%;
		margin-top:10px;
	}

	#footerSystemLinks{
		width:100%;
		max-width:none;
	}

	#footerSystemLinksUL li{
		width:25%;
	}
	#sellAd{
		background:none !important;
	}
	#sellTechOptions li{
		width:49% !important;
		margin:0 !important;
		float:left;
		clear:left;
	}
	#sellTechOptions li.col2{
		float:right;
		clear:right;
	}
	#sellTechOptions li.col3{
		padding-top:14px;
	}
	#sellTechOptions li.col4{
		float:right;
		clear:right;
		padding-top:14px;
	}
	#resultsPage li.leftColumn {
		display:none!important;
	}
	#resultsPage li.rightColumn {
		margin-left:0 !important;
		width:100% !important;
	}
	#resultsPage li.rightColumnMyAds{
		display:none;
	}
	#resultsPage li.leftColumnMyAds{
		margin-left:0 !important;
		width:100% !important;
	}
	#carPage li.rightColumn {
		display:none;
	}
	#carPage li.leftColumn {
		max-width:none !important;
		margin-right:0 !important;
	}
	#carPage table.dowble{
		width:100% !important;
	}
	#carPage table.options{
		width:100% !important;
	}
	#callDealer{
		display:none;
	}
	#callDealerInside{
		display:inline-block;
	}
}
@media (max-width: 650px) {
   .banner-megabord {
   		display: none;
   }
   .banner-megabord-small {
   		display: block;
   }
}
@media (max-width: 840px) {
    #catLinks{
        display:none !important;
    }
    #headerPanel{
        height:65px !important;
    }
}
@media (max-width: 760px) {
	#juicebox-container{
		padding-right:0;
	}
	.galleryBanner{
		display:none;
	}
	.galleryCloseBtn{
		right:10px;
	}
	.bannerUnderNavBottom{
		display: none;
	}
	.bannerUnderNavBottom760{
	    display: block;
	    margin-top: 10px;
	    text-align: right;
	    clear:both;
	}

	.topMenu .items a#menuMy span{
		display: none;
	}

}
@media (max-width: 740px) {
    #searchBox li{
		width:100%;
	}
	#searchBox li.subButton{
		width:50%;
	}
}
@media (max-width: 710px) {
	#carPage .navLinks a#prev::after, #carPage .navLinks a#prev2::after{
		content: "Предишна";
	}
	#carPage .navLinks a#next::before, #carPage .navLinks a#next2::before{
		 content: "Следваща";
	}
    .topMenu .items a#menuBuy::after{
		 content: "Купи" !important;
	}
	.topMenu .items span#menuSell::after{
		 content: " Продай" !important;
	}
	.topMenu .items a#menuNews::after{
		 content: "Новини" !important;
	}
	.topMenu .items a#menuBuy,
	.topMenu .items span#menuSell,
	.topMenu .items a#menuNews
	{
		 padding:0 10px 0 10px !important;
	}
}
@media (max-width: 680px) {
	#carGallery{
		width:100% !important;
	}
	#carGallery li.smallPhotos{
		display:none;
	}
	#carGallery li.bigPhoto{
		width:100% !important;
		max-width:380px;
		margin:0 auto !important;
		float:none !important;
	}
	#carGallery li.bigPhoto a img.photo{
		width:100% !important;
		height:auto !important;
	}
	#carGallery li.bigPhoto span{
		display:block !important;
	}
}
@media (max-width: 650px) {
	#scheme23L{
		width:100%;
	}
	#scheme23R{
		display:none;
	}
	ul#searchBox li.large{
		width:100%;
	}
	ul#searchBox li{
		margin-top:10px;
		width:48%;
	}
	ul#searchBox li.new{
		clear:both;
		margin-right:4%;
	}
	ul#searchBox li.subButton{
		width:50% !important;
		margin:0;
		text-align:center;
		padding-top:13px;
	}
	.show_mobile_banners{
		display:block;
		margin-bottom:15px;
		text-align: center;
	}
}
@media (max-width: 620px) {
	#carPage .navLinks a#newSearch::after, #carPage .navLinks a#newSearch2::after{
		 content: "Търсене";
	}

	#carPage .navLinks a#corection::after, #carPage .navLinks a#corection2::after{
		 content: "Корекция";
	}

	#carPage .navLinks a#results::after, #carPage .navLinks a#results2::after{
		 content: "Резултати";
	}

	#carPage .navLinks a#sendAlert::after, #carPage .navLinks a#sendAlert2::after{
		 content: "Нередност";
	}
}
@media (max-width: 600px) {
	#publikuvaneContacts li{
		width:100% !important;
		margin:10px 0 0 !important;
	}
	#searchBoxPage li{
		width:100% !important;
		margin:10px 0 0 !important;
	}
	#resultsPage div.info div{
		margin-top:2px !important;
	}
	#resultsPage div.info span{
		display:none;
	}
	#resultsPage div.price{
		float:left !important;
		margin:3px 0 0 !important;
		clear:both !important;
	}
	#printLink{
		display:none;
	}
}
@media (max-width: 520px) {
	#footerSystemLinksUL li{
		clear:right;
		width:50%;
	}
	#copyright{
		margin-bottom:10px;
	}
}
@media (max-width: 480px) {
	#carPage table.options th{
		width:94% !important;
		padding-left:3% !important;
		padding-right:3% !important;
		float:left;
		border-left:0 !important;
		background:#f2f2f2;
	}
	#carPage table.options th.secColumn{
		background:#FFF;
	}

	#carPage table.dowble th, #carPage table.dowble td{
		width:44% !important;
		padding-left:3% !important;
		padding-right:3% !important;
		float:left;
		text-align:left !important;
	}
	#carPage table.dowble th{
		clear:both;
		border-left:0 !important;
	}
	#carPage table.dowble tr{
		background:none !important;
		background:#f2f2f2 !important;
	}
	#carPage table.dowble th.secColumn, #carPage table.dowble td.secColumn{
		background:#FFF !important;
	}
}
@media (max-width: 450px) {
	#resultsPage li.text{
		padding-left:0 !important;
		margin-top:107px!important;
		min-height:inherit!important;
	}
	#resultsPage li.morePhotos{
		display:none!important;
	}
	#resultsPage div.price{
		position:absolute!important;
		top:15px!important;
		right:10px!important;
	}
}
@media (max-width: 440px) {
	#slogan{
		display:none !important;
	}
	.topMenu .items a#menuMy span{
		display:none !important;
	}
	.topMenu .items a#menuMy{
		border-left: none !important;
		padding: 0 10px 0 10px !important;
	}
}
@media (max-width: 430px) {
	#carPage .pageActions #shareLink::after{
		content: " ";
	}
}
@media (max-width: 400px) {
	#searchBox li{
		width:100% !important;
	}
	#sellTechOptions li{
		width:100% !important;
		margin:0 !important;
		float:left;
		clear:both;
	}
	#sellTechOptions div.legend{
		display:none;
	}
	#sellTechOptions li.col2, #sellTechOptions li.col3, #sellTechOptions li.col4{
		padding-top:15px;
	}
}
@media (max-width: 320px) {
	#resultsPage div.price{
		position:static;
	}
}

a.videoLink {display:inline-block;line-height:20px; padding:0 7px 0 25px; margin-top:10px; background: url(//www.auto.bg/images/video.png) 7px 7px no-repeat #BF111B;color:#FFF;text-decoration:none;font-size:13px;}
a.videoLink:hover {background: url(//www.auto.bg/images/video.png) 7px 7px no-repeat #ca3941;}


.reviewsPage {
	margin-left: 0 !important;
	width: 100% !important;
}

.reviewsPage .reviewResults .resultItem .photo{
	position:absolute;
	width:178px;
}
.reviewsPage .reviewResults .resultItem .photo img{
	width:178px;
	height:100px;
    object-fit: cover;
}
.reviewsPage .reviewResults .resultItem li.text{
	padding-left: 188px !important;
}

.reviewsPage button.more{
	background: #BF111B;
	width: 100%;
	color: #fff;
	border: 0;
	padding: 5px 5px 9px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	font-size: 2.4em;
	font-weight: bold;
	margin-top:15px;
	cursor:pointer;
}
.reviewsPage button.more:hover{
	background: #ca3941;
}
.reviewsPage button.more span.button-text{
	display:block;
	margin-bottom:2px;
}
.reviewsPage button.more span.button-subtext{
	display:block;
	font-size:16px;
	font-weight:normal;
}

.video-container {
	position:relative;
	padding-bottom:51.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}
.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
a.titleClip {
	display: block;
	background: #38618C;
	padding: 0 9px;
	color: #FFF;
	line-height: 32px;
    font-weight: bold;
    font-size: 15.5px;
	text-decoration: none;
	margin-top: 10px;
}

.fbShare {
	position: relative;
	text-align: center;
	margin-bottom: 10px;
	margin-top: 0;
	border: 2px solid #4267b2;
	color: #4267b2;
	font-size: 16px;
	font-weight: bold;
	padding-top: 20px;
}
.fbShare img{
	margin-top: 5px;
	position: absolute;
	top: -15px;
	left: 50%;
	margin-left: -125px;
}
.fbShare img.spec{
	margin-top:0;
}
.fbShare button{
	background: url(//www.auto.bg/images/ico/F_icon.svg) 20px center no-repeat #4267b2;
	margin-top: 8px;
	margin-bottom: 5px;
	width: 215px;
	color: #fff;
	border: 0;
	padding: 7px 10px 6px 44px;
	-webkit-border-radius: 3px;
	text-align: left;
	border-radius: 5px;
	font-size: 16px;
	font-weight: bold;
	/*text-shadow: 1px 1px 1px #0a5eb1;*/
	cursor:pointer;
}
.fbShare button:hover{
	background-color: #2a4c91;
}
.regMessage {
	color: #FFF !important;
	font-size: 20px;
	margin: 0 0 15px;
	font-weight: normal !important;
	text-align: center;
	background: #397638;
	padding: 20px 15px;
}
/*RESPONSIVE DESIGN*/
/*NOTHING AFTER THAT!*/

/* LOG */
.newLogin {
	background: #FFF;
	width: 820px;
	padding: 20px;
	margin: 50px auto;
}
.newLogin .formsWrapper {
	clear: both;
	border: 2px solid #DDD;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	padding: 20px 20px 27px 20px;
	margin-top: -3px;
}
.newLogin a.loginButton {
	/*float: right;*/
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 5px;
	line-height: 44px;
	padding: 0 15px;
	background: #09F;
	color: #FFF;
	font-size: 18px;
	border-radius: 5px;
	text-decoration: none;
	transition: background .1s;
	font-weight: bold;
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.newLogin a.loginButton:hover {
	background: #40b3ff;
}
.newLogin a.loginButtonWhite {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 5px;
    line-height: 42px;
    padding: 0 15px;
    background: #FFF;
    color: #09F;
    font-size: 18px;
    border: #09F 1px solid;
    border-radius: 5px;
    text-decoration: none;
    transition: background .1s;
    font-weight: bold;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.newLogin a.loginButtonWhite:hover {
    color: #aa2527;
}
.logPopup {
	display: none;
	position: fixed;
	z-index: 999999999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.6);
}
.logPopupFrame {
	display:block;
	width:860px;
	height:550px;
	margin:auto;
	margin-top:5%;
}



.sitemap{
	margin-top: 20px;
}
.sitemap div{
	display: block;
	width: 315px;
	float: left;
}
.sitemap ul{
	display: inline-block;
	list-style: circle inside;
	margin: 0 0 20px;
	padding: 0;
}
.sitemap li{
	float: none;
}
.sitemap a{
	display: inline-block;
	font-size: 13px;
	line-height: 25px;
}
.sitemap a.main{
	display: block;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 5px;
}
.catIcons {
	width: 100%;
	max-width: 660px;
	margin: 0 auto;
}
.catIcons a{
	display: inline-block;
	float: left;
	width: 155px;
	text-align: center;
	font-size: 13px;
	text-decoration: none;
}
.catIcons a span{
	display: block;
	height: 40px;
	text-decoration: underline;
	color: #1681b2;
}
.catIcons a:hover span{
	text-decoration:none;
	color: #ca3941;
}

.catIcons a img{
	transition:opacity .3s;
}
.catIcons a:hover img{
	opacity: .7;
}
button.btn404{
	background: #1681b2;
	width: 100%;
	color: #fff;
	border: 0;
	padding: 5px 5px 9px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	font-size: 2.4em;
	font-weight: bold;
	margin-top:25px;
	cursor:pointer;
	transition:background .5s;
}
button.btn404:hover{
	background: #ca3941;
}
button.btn404 span.button-text{
	display:block;
	margin-bottom:2px;
}
button.btn404 span.button-subtext{
	display:block;
	font-size:16px;
	font-weight:normal;
}
.breadcrumbs {
	width: 100%;
	max-width: 980px;
	margin: 10px auto -5px;
}
.breadcrumbs img{
	width: 16px;
	height: 16px;
	vertical-align: -4px;
	margin-right: 6px;
}
.rating {
	display: inline-flex;
}
.rating.enabled {
	cursor: pointer;
}
.rating svg {
	height: 20px;
	width:auto;
	border-right: 2px solid white;
}

.rating svg:last-child {
	border-right: 0px;
}

.rating svg.hover .star {
	fill: #FFED85;
	stroke: #FFED85;
}

.rating svg.stroke_hover .star {
	stroke: #FFED85;
}


a.a24 {
	display: inline-block;
	margin: 20px 0 0 10px;
	color: #000;
	text-decoration: underline;
}
a.a24:hover {
	text-decoration: none;
	color: #38618C;
}
a.a24 img{
	vertical-align: -3px; margin-right: 5px;
}


select.avtokashtiLocation {
	width: 300px;
	height: 32px;
	padding: 0 10px;
	font-weight: bold;
	border: 2px solid #6E8EB0;
	background: #6E8EB0;
	color: #FFF;
	font-size: 16px;
}

input[type="text"].avtokashtiName {
	width: 260px;
	height: 32px;
	padding: 0 10px;
	font-weight: bold;
	border: 1px solid #6E8EB0;
	background: #FFF;
	color: #333;
	font-size: 16px;
	font-weight: bold;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

button.searchAvtokashti {
	line-height: 30px;
	margin-left: 5px;
	vertical-align: bottom;
	background-image: url("/images/ico/search.svg") !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
}

.similar {
	width: 100%;
	margin-top: 20px;
}

.similar div {
	/*white-space: nowrap;*/
	display: block;
	overflow: scroll;
	color: #999;
}

.similar .name {
	font-weight: bold;
	color: #777;
	margin-bottom: 5px;
}

.similar div a {
	text-decoration: none;
	color: #999;
}

.seo {
	margin: 10px 0;
}
.seo textarea {
	height: 300px;
}
.hidden {
	display: none;
}


.sitemap ul.float li {
	float: left;
	width: 33%;
}

a.callBTN {
	display: inline-block;
	margin-top: 10px;
	width: 100%;
	padding: 0 22px;
	box-sizing: border-box;
	background: #00a651;
	border-radius: 5px;
	line-height: 32px;
	text-align: left;
	color: #FFF;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
}
a.callBTN:hover {
	background: #008f46;
	text-decoration: none;
	color: #FFF;
}
a.callBTN span {
	font-size: 12px !important;
	line-height: 12px;
	font-weight: normal;
	opacity: .7;
}
a.callBTN img {
	height: 18px;
	vertical-align: -4px;
	margin-right: 3px;
}

.titleBig{
	font-size:21px;
	font-weight:bold;
}


ul.questionForms {
	width: 100%;
	display: inline-block;
	list-style: none;
	margin: 20px 0 0;
	padding: 0 20px;
	box-sizing: border-box;
}
ul.questionForms li.data{
	float: left;
	width: 40%;
	padding-right: 30px;
	box-sizing: border-box;
}
ul.questionForms li.message{
	float: left;
	width: 60%;
}
ul.questionForms label{
	display: block;
	margin-bottom: 5px;
	font-size: 13px;
	font-weight: bold;
}
ul.questionForms textarea{
	width: 100%;
	padding: 5px 10px;
	box-sizing: border-box;
}
ul.questionForms input[type="text"] , ul.questionForms input[type="tel"], ul.questionForms input[type="email"]{
	width: 100%;
	height: 28px;
	padding: 0 10px;
	margin-bottom: 10px;
	box-sizing: border-box;
}
ul.questionForms input[type="text"]:last-child{
	margin-bottom: 0;
}
ul.questionForms .buttons {
	margin-top: 10px;
}
ul.questionForms .buttons button {
	margin-top: 15px;
}


.tipOptions {
	margin: 20px 0;
}
.tipOptions label {
	font-size: 15px;
	font-weight: bold;
}
.tipOptions div {
	margin-top: 5px;
	margin-bottom: 20px;
	color: #666;
	font-size: 14px;
	font-style: italic;
	font-family: Arial;
	padding-left: 27px;
}
.tipOptions label span {
	font-weight: normal;
	font-style: italic;
	font-family: Arial;
}

.tipPortfeil {
	margin-top: 15px;
	padding: 15px 20px;
	border: 1px solid #0b9a0060;
	border-radius: 10px;
	color: #0B9A00;
	font-size: 16px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACE0lEQVRYhd2X0W3jMAyG/0eFfMkIGcEb1CNkg/MI2aDeoH0LSD/IGzQbxBtcNrhs4Gyge6jkk205ba+nGDgBBAxBoD5S5C8Z+OZgRcWKnhU9Kaw5Yvddn3cHCWpWnE2D0hyxY4VL2JkFP/7JhqZBSQoLDNE6FtxgsSVBuwDgWOFI8IsaPMNi+3ebv0fYk+DiATrvuIXF1qd+vrHimpizrCi+BECCvd+w9gCOFc6DVQubn/zaYiFD509nxJ+3Mw3KAMOKDgBClKS4kuLEim4jOEx9hKzFllqXHKx4ZYWLv1lRmQZl5LD6wMcMgATtZwG6kFISXFhw899tiP4jH6ljIME+udg0KI3iKfQyKa4bwcEXnCNBHbdeqA0S7Enx08/3pLDhnM0ROxbc4gJNRfo6Iaz9vGNFEQrOHLELdcEK51txP40wgAzFZrGNIKppiupEiupAHtI4VLd3FM7RF+CSDrQ+kCLWjzHAuGfHdI8Y95Qsg51Ng3JNgGDFqgAjHVgpA93/D0CKKwlaEtRT47jbMp1xDbyroFE8pWzQg1yb80RdExk6ZQEA5upKgstiHeQoLo6u4HDzJSQ/H0AccVA+nr+gsgD0/oaMo+1J8Mbz92MWgD9vhDu3JOcsQlY4Frx434VpUKYsWxuOUq+w1OA5ZWtLsVv3NozfhQsikRvgNABsBIdHA8xeRRvB4RGZIMFl+k/wGwmIvL/QRbNbAAAAAElFTkSuQmCC) 10px center no-repeat;
	padding-left: 55px;
}
.tipPortfeil button {
	float: right;
	margin: -7px -12px 0 0;
	padding: 0 20px;
	color: #0B9A00;
	border: none;
	border-radius: 5px;
	white-space: nowrap;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	line-height: 28px;
	display: inline-block;
	width: auto;
	height: 34px;
	cursor: pointer;
	background: #e5ede5;
	vertical-align: bottom;
}
.tipPortfeil button:hover {
	color: #FFF;
	background: #0B9A00;
}

.tipNote {
	margin-top: 15px;
	font-size: 14px;
	/*border-left: 4px solid #0B9A00;
	padding-left: 13px;
	margin-left: 10px;*/
	/*color: #0B9A00;*/
	font-weight: bold;
}



.filelist {
	display: inline;
	line-height: 60px;
}

.imgplupload_error {
	background-color: white;
	position: absolute;
	left: 103px;
	border: 1px solid #ccc;
	padding: 10px;
	width: 400px;

}

@keyframes fade-in-up {
	0% {
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.video video, .video iframe {
	max-width: 100%;
	max-height: 100%;
}

.video.stuck {
	position: fixed;
	bottom: 40px;
	right: 40px;
	width: 300px;
	height: 170px;
	transform: translateY(100%);
	animation: fade-in-up 0.75s ease forwards;
	z-index: 1;
}

.dds {
  font-size: 12px;
  font-weight: normal;
}
